import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

// Import Swiper styles
import { heroSliderListApi } from '../../apis/strapiApi'
import { colors } from '../../colors'
import { Slider } from '../../types'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import B2BContainer from '../B2BContainer/B2BContainer'
import PlayDemoButton from '../PlayDemoButton/PlayDemoButton'

const HeroSlider = () => {
  const theme = useTheme()
  const [swiper, setSwiper] = useState(useSwiper())
  const [heroSliderList, setHeroSliderList] = useState<Slider[]>()
  const { locale } = useParams()

  //TODO: Use SWR to fetch data
  useEffect(() => {
    if (locale) {
      const getSliders = async () => {
        const data = await heroSliderListApi(locale)
        setHeroSliderList(data?.data)
      }
      getSliders()
    }
  }, [locale])

  if (!heroSliderList)
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          height: ['143.55vw', '31vw', 278, 370, 445],
        }}
      ></Skeleton>
    )

  return (
    <Box
      sx={{
        height: ['143.55vw', '31vw', 278, 370, 445],
        background: `linear-gradient(0deg, #221d31 60%, #221d31 84.5%, ${colors.gray[2]} 60%)`,
        '.swiper-wrapper': {
          alignItems: 'end',
        },
        '&:hover .swiperNav': {
          '.MuiIconButton-root': {
            border: `1px solid ${colors.gray[12]}`,
            path: {
              color: colors.gray[12],
              opacity: 1,
            },
          },
        },

        '.swiper-pagination': {
          bottom: { sm: 0, md: 2, lg: 16 },
          '&-bullet': {
            backgroundColor: colors.gray[12],
            opacity: 1,
            '&-active': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },

        '.swiperNav': {
          display: ['none', null, null, 'flex'],
          position: 'absolute',
          top: [null, null, null, 185, 225],
          zIndex: 1,
          width: 50,
          height: 50,

          '.MuiIconButton-root': {
            height: 50,
            borderRadius: '50%',
            border: `1px solid ${colors.gray[5]} `,
            path: {
              color: colors.gray[5],
            },
          },

          '&--prev': { left: 70 },
          '&--next': { right: 70 },
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: theme.breakpoints.values.xl,
          position: 'relative',
          zIndex: 2,
          m: 'auto',
        }}
      >
        <Stack className="swiperNav swiperNav--next">
          <IconButton onClick={() => swiper.slideNext()}>
            <FontAwesomeIcon icon={faChevronRight} fontSize={16} />
          </IconButton>
        </Stack>
        <Stack className="swiperNav swiperNav--prev">
          <IconButton onClick={() => swiper.slidePrev()}>
            <FontAwesomeIcon fixedWidth icon={faChevronLeft} fontSize={16} />
          </IconButton>
        </Stack>
      </Stack>
      <Swiper
        modules={[Pagination]}
        onSwiper={(swiper) => {
          setSwiper(swiper)
        }}
        pagination={{ clickable: true }}
        grabCursor
        loop
        on={{
          afterInit: (e) => {
            e.slideTo(0)
          },
        }}
        autoplay={{ delay: 5000 }}
      >
        {heroSliderList.map((item) => (
          <SwiperSlide key={item.id}>
            <Slide {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export interface SlideProps {
  headline: string
  subtitle: string
  gameId?: string
  gameplay?: string
  slug: string
}

const Slide = (props: Slider) => {
  const { attributes } = props
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { locale } = useParams()

  if (!attributes) return <></>

  const backgroundImgUrl = sm
    ? attributes.backgroundImg?.data?.attributes?.url
    : attributes.backgroundImgMobile?.data?.attributes?.url
  const logoUrl = attributes.logo?.data?.attributes?.url

  return (
    <B2BContainer
      sx={{
        display: 'flex',
        position: 'relative',
        '.banner': { width: 1 },
        '.MuiButton-root': { minWidth: 132 },
        textAlign: 'center',
        '.MuiTypography-root': {
          color: colors.gray[12],
        },

        '.MuiSkeleton-root': {
          height: 1,
        },
      }}
    >
      <Skeleton variant="rectangular" />
      <Box sx={{ height: 'auto', img: { height: 'auto' } }}>
        <img src={backgroundImgUrl} className="banner" alt="" />
      </Box>
      <Stack
        sx={{
          position: 'absolute',
          bottom: [60, 20, 25, 40, 60],
          width: [1, 0.57],
          img: {
            width: ['60vw', 145, 120, 200, 285],
            maxWidth: 285,
          },
        }}
      >
        <Stack
          gap={1}
          sx={{
            alignItems: 'center',
            '& > *': {
              my: 0.5,
            },
          }}
        >
          <Stack alignItems="center">
            <img src={logoUrl} alt="" />
            <Stack
              gap={1}
              sx={{
                '& > *': {
                  my: 0.5,
                },
              }}
            >
              <Typography variant="headline">{attributes.headline}</Typography>
              <Typography variant="bodySmall">
                {attributes.subHeadline}
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {attributes.gameId && attributes.slug && (
              <PlayDemoButton
                slug={attributes.slug}
                gameId={attributes.gameId}
                variant="outlined"
                color="secondary"
              />
            )}
            <Box sx={{ ml: 1.5 }}>
              <Link to={`/${locale}/products/${attributes.slug}`}>
                <Button size={md ? 'medium' : 'small'} variant="contained">
                  {t('general.read_more')}
                </Button>
              </Link>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </B2BContainer>
  )
}

export default HeroSlider
