import { Card, Stack } from '@mui/material'
import InfoModalContent from './InfoModalContent'
// import './infoModalStyles.scss';

const InfoModal = () => {
  return (
    <Stack sx={{ height: '100%', justifyContent: 'center' }}>
      <Card
        sx={{
          width: 1,
          height: 1,
          mx: 'auto',
          background: 'transparent',
          overflow: 'scroll',
        }}
        elevation={0}
      >
        <InfoModalContent />
      </Card>
    </Stack>
  )
}

export default InfoModal
