import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Overview } from './1_Overview'
import { AboutGame } from './2_AboutGame'
import { BetPanel } from './3_BetPanel'
import { AnimalNumbers } from './4_AnimalNumbers'
import { Mode } from './4_Mode'
import { ExtraPanel } from './5_ExtraPanel'
import { SupportedLanguages } from './6_SupportedLanguages'
import info from './info.json'
import { ComingSoon, ProductBanner, ProductDetails } from '../../../components'
import Content from '../../../components/ProductDetails/Content'
import { gameDetailsApi } from '../../../apis/strapiApi'
import { GameDetails, Game } from '../../../types'

const Details = () => {
  const [details, setDetails] = useState<GameDetails>()
  const [game, setGame] = useState<Game>()
  const { product, locale } = useParams()

  //TODO: Use SWR to fetch articles
  useEffect(() => {
    if (product) {
      const getDetails = async () => {
        const data = await gameDetailsApi(product, locale)
        setGame(data?.data[0])
        setDetails(data?.data[0]?.attributes.detailsPage[0])
      }
      getDetails()
    }
  }, [locale, product])

  return (
    <Box>
      <Helmet>
        {game?.attributes && <title>{game.attributes.name} - iMoon B2B</title>}
      </Helmet>
      <ProductBanner
        images={{
          desktop: details?.banner?.bannerImg.data?.attributes?.url || '',
          mobile: details?.banner?.bannerImgMobile.data?.attributes?.url || '',
        }}
      />
      <ProductDetails>
        {!details?.releaseDate && (
          <Overview {...details?.overview} {...game?.attributes} />
        )}
        {details && (
          <>
            {details.aboutGame && (
              <Content
                title={info.about_game.title}
                content={<AboutGame {...details?.aboutGame} />}
              />
            )}
            {details.betPanel && (
              <Content
                title={info.bet_panel.title}
                content={<BetPanel {...details?.betPanel} />}
              />
            )}
            {details.gameMode && (
              <Content
                title={info.mode.title}
                content={<Mode {...details?.gameMode} />}
              />
            )}
            {details.animalNumbers && (
              <Content
                title={info.animal_numbers.title}
                content={<AnimalNumbers {...details?.animalNumbers} />}
              />
            )}
            {details.extraPanel && (
              <Content
                title={info.extra_panel.title}
                content={<ExtraPanel list={details?.extraPanel} />}
              />
            )}
            {details.supportedLanguages && (
              <Content
                title={info.supported_languages.title}
                content={
                  <SupportedLanguages {...details?.supportedLanguages} />
                }
              />
            )}
            {details.releaseDate && (
              <ComingSoon releaseDate={details.releaseDate} />
            )}
          </>
        )}
      </ProductDetails>
    </Box>
  )
}

export default Details
