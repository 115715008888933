import {
  Box,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect } from 'react'
import { PurpleDetail } from '../../../types'

export const SupportedLanguages = (props: PurpleDetail) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))

  useEffect(() => {
    const textDom = document.getElementById(`sl__text`)
    if (textDom) textDom.innerHTML = props.text
  }, [props])

  return (
    <Stack sx={{ justifyContent: 'center', gap: 3 }}>
      <Typography variant="body" id="sl__text"></Typography>
      <Box
        sx={{
          position: 'relative',
          img: { position: 'relative', width: 1, zIndex: 1 },
          height: ['calc(147vw - 120px)', 219, 153, 179],
          width: { xs: 1, md: 504, lg: 588 },
          m: 'auto',

          '.MuiSkeleton-root': {
            position: 'absolute',
            inset: 0,
            height: 1,
          },
        }}
      >
        <img
          src={
            md
              ? props.img?.data?.attributes.url || ''
              : props.imgMobile?.data?.attributes.url || ''
          }
          alt="group of flags"
        />
      </Box>
    </Stack>
  )
}
