import { faRocketLaunch } from '@fortawesome/pro-duotone-svg-icons'
import Section from '../../components/Section/Section'

// Import Swiper React components
import { SwiperSlide } from 'swiper/react'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { newProductListApi } from '../../apis/strapiApi'
import { CollectionSlider } from '../../components/CollectionSlider/CollectionSlider'
import { GameCard } from '../../components/GameCard/GameCard'
import { gcSkeletonList } from '../../constants'
import { Game } from '../../types'
import GameCardSkeleton from '../../components/GameCard/GameCardSkeleton'

export interface GameList {
  id: string
  attributes?: {
    games: { data: Game[] }
  }
}

const NewProductsCollection = () => {
  const [newProductList, setNewProductList] = useState<GameList>()
  const { locale } = useParams()

  useEffect(() => {
    const getList = async () => {
      const data = await newProductListApi(locale)
      setNewProductList(data?.data)
    }
    getList()
  }, [locale])

  return (
    <Section title="section.new_products" icon={faRocketLaunch}>
      <CollectionSlider>
        {newProductList
          ? newProductList?.attributes?.games.data.map((gameCard) => (
              <SwiperSlide key={gameCard.id}>
                <GameCard {...gameCard} />
              </SwiperSlide>
            ))
          : gcSkeletonList.map((gameCard, index) => (
              <SwiperSlide key={index}>
                <GameCardSkeleton />
              </SwiperSlide>
            ))}
      </CollectionSlider>
    </Section>
  )
}

export default NewProductsCollection
