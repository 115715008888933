import { colors, gradients } from '../../colors';
import { Box, BoxProps, SxProps, Theme } from '@mui/material';

const sx: SxProps<Theme> = {
  position: 'relative',
  background: gradients[7],
  border: `1px solid ${colors.gray[3]}`,
  borderRadius: '12px',
  p: 2,
  gap: 2,
  '.MuiBox-root': {
    width: 1,
    justifyContent: 'space-between',
  },
};

const Banner = (props: BoxProps) => {
  const { children } = props;
  return <Box sx={{ ...sx, ...props.sx }}>{children}</Box>;
};

export default Banner;
