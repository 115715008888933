import {
  Box,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { articleApi } from '../../apis/strapiApi'
import { colors } from '../../colors'
import { NewsSection } from '../../sections'
import { Article } from '../../types'
import { ImageSlider } from '../ImageSlider/ImageSlider'
import PageContent from '../PageContent/PageContent'
import ArticleSkeleton from './ArticleSkeleton'
import { useTranslation } from 'react-i18next'

const ArticleDetails = () => {
  const { article } = useParams()
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  const { locale } = useParams()
  const { t } = useTranslation()

  const [details, setDetails] = useState<Article>()

  //TODO: Use SWR to fetch articles
  useEffect(() => {
    if (article) {
      const getArticles = async () => {
        const data = await articleApi(article, locale)
        setDetails(data?.data)
        console.log('article data::', data?.data)
      }
      getArticles()
    }
  }, [locale, article])

  useEffect(() => {
    const contentDom = document.getElementById(`${article}__content`)
    if (contentDom) contentDom.innerHTML = details?.attributes?.content || ''
  }, [details])

  if (!details) return <ArticleSkeleton />

  const { attributes } = details

  return (
    <PageContent
      sx={{
        '& > *:not(:first-child):not(:last-child)': {
          my: 1,
        },
      }}
    >
      <Helmet>
        <title>{attributes?.title} - iMoon B2B</title>
      </Helmet>
      <Stack
        sx={{
          img: {
            borderRadius: 3,
          },
        }}
      >
        <Stack gap={0.75} sx={{ mb: 3 }}>
          <Typography
            variant={md ? 'title3' : 'bodyBold'}
            sx={{ color: colors.gray[12], textAlign: 'center' }}
          >
            {attributes?.title}
          </Typography>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              '.MuiChip-root': {
                color: colors.gray[3],
                borderRadius: 2.5,
                fontSize: 12,
                background: '#C7C2FF',
                fontWeight: 600,
                height: 26,
              },
            }}
          >
            {attributes?.tags && (
              <Chip
                label={
                  t(`news.${attributes.tags.toLowerCase()}`) || attributes.tags
                }
              />
            )}
            <Typography
              variant="caption"
              sx={{
                color: colors.gray[6],
                textAlign: 'center',
                marginInlineStart: 2,
              }}
            >
              {dayjs(attributes?.date).format('MMM D, YYYY')}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          {attributes?.assets && attributes?.assets.data.length > 1 ? (
            <ImageSlider
              imgUrlList={attributes?.assets.data.map(
                (item) => item.attributes?.url
              )}
            />
          ) : (
            <Box className="img--container">
              <img
                src={attributes?.assets?.data[0].attributes?.url}
                alt="iMoon booth"
                className="img__main"
              />
            </Box>
          )}
        </Stack>
        <Typography
          variant="body"
          sx={{ color: colors.gray[9] }}
          id={`${article}__content`}
        ></Typography>
      </Stack>
      <NewsSection />
    </PageContent>
  )
}

export default ArticleDetails
