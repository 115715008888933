import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import B2BContainer from '../B2BContainer/B2BContainer'

interface HeroBannerProps {
  images: { desktop: string; mobile: string }
  title?: string
}

const HeroBanner = (props: HeroBannerProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <B2BContainer
      sx={{
        img: { position: 'relative', zIndex: 0 },
        flex: 1,
        '.MuiSkeleton-root': {
          position: 'absolute',
          inset: 0,
          height: 1,
          width: 1,
        },
        '.MuiTypography-root': {
          textAlign: 'center',
          width: 1,
        },

        '.banner__container': {
          height: ['143.6vw', 255, 300, 400, 480],
          position: 'relative',
          overflow: 'hidden',
        },
      }}
    >
      <Box flex={1}>
        {md ? <DesktopBanner {...props} /> : <MobileBanner {...props} />}
      </Box>
    </B2BContainer>
  )
}

const MobileBanner = (props: HeroBannerProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Box className={'banner__container'}>
        <Skeleton variant="rectangular" />
        <img src={`${props.images.mobile}?1`} alt="" width={'100%'} />
      </Box>
      {props.title && (
        <Typography
          variant={'title3'}
          textAlign="center"
          component="p"
          sx={{ transform: 'translateY(-30vw)' }}
        >
          {t(props.title)}
        </Typography>
      )}
    </>
  )
}

const DesktopBanner = (props: HeroBannerProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Box className={'banner__container'}>
        <Skeleton variant="rectangular" />
        <img src={`${props.images.desktop}?2`} alt="" width={'100%'} />
      </Box>
      {props.title && (
        <Typography
          variant={'title2'}
          textAlign="center"
          component="p"
          sx={{ mb: 6, mt: 3 }}
        >
          {t(props.title)}
        </Typography>
      )}
    </>
  )
}

export default HeroBanner
