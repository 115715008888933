export const slugify = (input: string, separator = '-') => {
  const slug = input
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, separator)
    .replace(new RegExp(`^${separator}|${separator}$`, 'g'), '')

  return slug
}

export const removeHtmlTags = (text: string) => {
  const div = document.createElement('div')
  div.innerHTML = text
  return div.textContent || div.innerText
}
