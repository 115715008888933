import {
  Box,
  FormControl,
  FormControlProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Flag from 'react-svg-country-flags'

const lngs = [
  { value: 'en', nativeName: 'English', flag: 'gb' },
  { value: 'de', nativeName: 'German', flag: 'de' },
  { value: 'es', nativeName: 'Spanish', flag: 'es' },
  { value: 'fi', nativeName: 'Finnish', flag: 'fi' },
  { value: 'fr', nativeName: 'French', flag: 'fr' },
  { value: 'it', nativeName: 'Italian', flag: 'it' },
  { value: 'ja', nativeName: 'Japanese', flag: 'jp' },
  { value: 'pt', nativeName: 'Portuguese', flag: 'br' },
  { value: 'ru', nativeName: 'Russian', flag: 'ru' },
  { value: 'sv', nativeName: 'Swedish', flag: 'se' },
  { value: 'zh', nativeName: 'Chinese', flag: 'cn' },
]

const LanguageSelect = (props: FormControlProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const pathname = useLocation().pathname
  const { locale } = useParams()
  const [language, setLanguage] = useState(i18n.resolvedLanguage)

  useEffect(() => {
    setLanguage(i18n.resolvedLanguage)
    // navigate(`${i18n.resolvedLanguage}`)
  }, [i18n.resolvedLanguage])

  const handleChange = (e: SelectChangeEvent) => {
    setLanguage(e.target.value)
    i18n.changeLanguage(e.target.value)
    locale && navigate(pathname.replace(locale, e.target.value))
  }

  return (
    <FormControl
      sx={{ ...props.sx, '.MuiInputBase-input': { padding: '12px 14px' } }}
    >
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={language}
        onChange={handleChange}
        sx={{ border: 'none' }}
      >
        {lngs.map((lng) => (
          <MenuItem value={lng.value} key={lng.value}>
            {md ? (
              <Flag country={lng.flag} />
            ) : (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Flag country={lng.flag} />
                <Typography variant="bodyBold"> {lng.nativeName}</Typography>
              </Box>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LanguageSelect
