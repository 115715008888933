import axios, { AxiosRequestConfig } from 'axios'

interface LaunchResponse {
  success: boolean
  url?: string
}

export const launchApi = async (
  gameId: string,
  gameplay: string,
  lang: string,
  extraData?: object
) => {
  const data = JSON.stringify({
    balance: 1000,
    gameId,
    currency: 'USD',
    lang,
    extraData: {
      gameplay,
      lang,
      ...extraData,
    },
  })

  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_FUN_LAUNCH_API_PATH}fun-launch`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  }

  try {
    const res: LaunchResponse = (await axios(config)).data
    console.log('success::', res)
    if (res.success) {
      return res.url
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

export const launchAlienApi = async (gameId: string) => {
  const data = JSON.stringify({
    gameId,
  })

  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_ALIEN_OPERATOR_BASE_API_PATH}fun-launch`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  }

  try {
    const res: LaunchResponse = (await axios(config)).data
    console.log('success::', res)
    if (res.success) {
      return res.url
    }
  } catch (error) {
    console.error(error)
    return false
  }
}
