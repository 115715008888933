import logoFooter from '../../assets/logo_footer.svg'
import { colors } from '../../colors'
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Card,
  Container,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { cdnUrl } from '../../constants'

const path = `${cdnUrl}license-logos/`

const socialList = [
  // { icon: faSquareYoutube, url: '#1' },
  { icon: faInstagram, url: 'https://www.instagram.com/imoon' },
  { icon: faLinkedin, url: 'https://www.linkedin.com/company/imoon-games/' },
]

const Footer = () => {
  const { t } = useTranslation()
  const [antilleLicense, setAntilleLicense] = useState('')

  const logoList = [
    { logo: 'sigma.png', href: 'https://sigma.world/' },
    { logo: 'bmm.png', href: 'https://bmm.com/' },
    {
      logo: 'antillephone.png',
      href: antilleLicense,
      id: 'antilleLinkFooter',
    },
    { logo: 'gli.png', href: 'https://gaminglabs.com/gli-certified-mark/' },
    {
      logo: 'itech.png',
      href: 'https://cdn.imoon.com/crashdemo/b2b/company/licenses/itech_uk.jpg',
    },
  ]

  const getAntilleLicense = () => {
    const antAnchor: HTMLAnchorElement | null = document.querySelector(
      '#apg-f5200ddd-281d-4644-b29b-899f07790f01 a'
    )
    let url = '#'
    if (antAnchor) {
      url = antAnchor.href
      setAntilleLicense(url)
    }

    return url
  }

  useEffect(() => {
    setTimeout(() => {
      getAntilleLicense()
    }, 2000)
  }, [])

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://f5200ddd-281d-4644-b29b-899f07790f01.snippet.antillephone.com/apg-seal.js"
        ></script>
      </Helmet>
      <Box
        id="FooterBox"
        sx={{
          textAlign: 'center',
          display: 'flex',
          py: 6,
          background:
            'linear-gradient(180deg, #1D1929 0%, rgba(29, 25, 41, 0) 100%)',
        }}
      >
        <Container maxWidth="md">
          <Stack sx={{ alignItems: 'center', gap: 3 }}>
            <img src={logoFooter} alt="" />
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.75 }}>
              {logoList.map((item) => (
                <LogoItem src={item.logo} href={item.href} key={item.logo} />
              ))}
            </Box>
            <Link
              sx={{
                cursor: 'pointer',
                '#apg-f5200ddd-281d-4644-b29b-899f07790f01': {
                  display: 'none',
                },
              }}
              target="_blank"
            >
              <div
                id="apg-f5200ddd-281d-4644-b29b-899f07790f01"
                data-apg-seal-id="f5200ddd-281d-4644-b29b-899f07790f01"
                data-apg-image-size="64"
                data-apg-image-type="basic-light-large"
              >
                View
              </div>
            </Link>
            <Typography variant="bodySmall" color={colors.primary[12]}>
              {t('footer.registration')}
            </Typography>
            <Stack>
              <Typography variant="bodyBold">
                {t('footer.community')}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1.5 }}>
                {socialList.map((item) => (
                  <IconButton
                    sx={{ width: 55, height: 55 }}
                    key={item.url}
                    href={item.url}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      fixedWidth
                      fontSize={36}
                      icon={item.icon}
                      color={colors.gray[7]}
                    />
                  </IconButton>
                ))}
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  )
}

interface LogoItemProps {
  id?: string
  src: string
  href?: string
}

const LogoItem = (props: LogoItemProps) => {
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: [60, 80, 100],
        height: [60, 80, 100],
        borderRadius: 4,
        background:
          'linear-gradient(295.7deg, #2A243A 49.91%, rgba(42, 36, 58, 0) 97.93%)',
        maxWidth: 1,
        img: {
          width: [35, 50, 60],
        },
        ...(props.href && {
          ':hover,:focus': {
            opacity: 0.8,
          },
        }),
      }}
    >
      <Link href={props.href} id={props.id}>
        <Stack>
          <img src={`${path}${props.src} `} alt="" />
        </Stack>
      </Link>
    </Card>
  )
}

export default Footer
