import { colors } from '../../colors';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, Input, InputAdornment, InputProps } from '@mui/material';

interface FormInputProps {
  icon: IconDefinition;
}

const FormInput = (props: FormInputProps & InputProps) => {
  return (
    <FormControl
      sx={{
        '.MuiInput-root': {
          p: 1.5,
          color: colors.gray[8],
          input: {
            pl: 0.5,
            fontSize: 14,
          },
        },
      }}
      fullWidth
    >
      <Input
        disableUnderline
        startAdornment={
          <InputAdornment position="start">
            <FontAwesomeIcon icon={props.icon} color={colors.gray[6]} />
          </InputAdornment>
        }
        {...props}
      />
    </FormControl>
  );
};

export default FormInput;
