import { faMinus, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  BoxProps,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { colors } from '../../colors'

interface ContentProps {
  title: string
  content: JSX.Element
  sx?: SxProps<Theme>
}

const Content = (props: ContentProps) => {
  const { content, title } = props
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box sx={props.sx}>
      {md ? (
        <Stack sx={{ gap: 3 }}>
          <Typography variant="headline" sx={{ color: colors.gray[12] }}>
            {title}
          </Typography>
          {content}
        </Stack>
      ) : (
        <Accordion
          sx={{ background: 'none' }}
          elevation={0}
          onChange={() => setExpanded((prev) => !prev)}
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={expanded ? faMinus : faPlus} />}
          >
            <Typography variant="headline" sx={{ color: colors.gray[12] }}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{content}</AccordionDetails>
        </Accordion>
      )}
    </Box>
  )
}

export default Content
