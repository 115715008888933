export const cdnUrl = 'https://cdn.imoon.com/crashdemo/b2b/'

export enum OPERATORS {
  ALIEN = 'alien',
  B2C = 'b2c',
}

export const gcSkeletonList = new Array(6).fill('')

export const maxFileSize = 5000000
