import React, { useEffect, useState } from 'react'
import { CollectionSlider, Section } from '../../components'
import { useTranslation } from 'react-i18next'
import { faHandshake } from '@fortawesome/pro-duotone-svg-icons'
import { SwiperSlide } from 'swiper/react'
import { Partner } from '../../types'
import { partnerListApi } from '../../apis/strapiApi'
import { Box } from '@mui/material'
import Marquee from 'react-fast-marquee'

const VerifiedPartners = () => {
  const { t } = useTranslation()
  const list = ['list', 'list', 'list', 'list', 'list', 'list', 'list', 'list']
  const [partnerList, setPartnerList] = useState<Partner[]>()

  //TODO: Use SWR to fetch data
  useEffect(() => {
    const getList = async () => {
      const data = await partnerListApi()
      setPartnerList(data?.data)
    }
    getList()
  }, [])

  if (!partnerList) return <></>

  return (
    <Section
      icon={faHandshake}
      title={t('section.verified_partners')}
      subtitle={t('section.verified_partners_subtitle') || ''}
    >
      <Box sx={{ mt: { xs: 1.5, md: 4.5 }, mx: { xs: '-24px', md: 0 } }}>
        <Marquee>
          {partnerList.map((item) => (
            <Box
              sx={{
                mx: 3,
                width: 1,
                img: { WebkitTransform: 'translateZ(0)' },
              }}
            >
              <img
                src={item.attributes.logo?.data.attributes.url}
                alt={item.attributes.name}
              />
            </Box>
          ))}
        </Marquee>
      </Box>
    </Section>
  )
}

export default VerifiedPartners
