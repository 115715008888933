import { Article } from '../../types';

export const skeletonArticleList: Article[] = [
  {
    id: '1',
    attributes: {
      content: '',
    },
  },
  {
    id: '2',
    attributes: {
      content: '',
    },
  },
  {
    id: '3',
    attributes: {
      content: '',
    },
  },
  {
    id: '4',
    attributes: {
      content: '',
    },
  },
  {
    id: '5',
    attributes: {
      content: '',
    },
  },
  {
    id: '6',
    attributes: {
      content: '',
    },
  },
];
