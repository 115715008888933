import { colors } from '../../colors'
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { t } from 'i18next'
import { FC } from 'react'
import { ThemeModal } from '..'

export const VerifyAgeModal: FC<{
  open: boolean
  handleClose: () => void
  handleCancel: () => void
}> = (props) => {
  const { open, handleClose, handleCancel } = props
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))

  const handleConfirmClick = () => {
    localStorage.setItem('ageVerificationKey', new Date().toISOString())
    handleClose()
  }

  return (
    <ThemeModal
      open={open}
      onClose={handleCancel}
      title="verify_age_modal.title"
    >
      <Stack gap={2.5}>
        <Typography color={colors.gray[9]} textAlign="center" fontWeight={800}>
          {t('verify_age_modal.p1')}
        </Typography>
        <Stack direction="row" gap={2}>
          <Button
            size={sm ? 'medium' : 'small'}
            fullWidth
            onClick={handleCancel}
          >
            {t('verify_age_modal.cancel')}
          </Button>
          <Button
            size={sm ? 'medium' : 'small'}
            // size="small"
            variant="contained"
            fullWidth
            onClick={handleConfirmClick}
            sx={{
              whiteSpace: 'nowrap',
              p: '6px 32px',
            }}
          >
            {t('verify_age_modal.confirm')}
          </Button>
        </Stack>
      </Stack>
    </ThemeModal>
  )
}
