import {
  Box,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { colors } from '../../colors'
import PageContent from '../PageContent/PageContent'

const ArticleSkeleton = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <PageContent sx={{ gap: 0 }}>
      <Stack
        gap={3}
        sx={{
          img: {
            borderRadius: 3,
          },
          '.img--container': {
            position: 'relative',
            height: ['calc(51.7vw - 24px)', 395, 467, 632],
          },
          '.img__main': {
            position: 'relative',
            width: 1,
            zIndex: 1,
          },

          '.MuiSkeleton-root': {
            borderRadius: 3,
            inset: 0,
            height: 1,
          },
        }}
      >
        <Stack gap={0.75}>
          <Typography
            variant={md ? 'title2' : 'bodyBold'}
            sx={{ color: colors.gray[12], textAlign: 'center' }}
          >
            <Skeleton variant="text" sx={{ width: '100%' }} />
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: colors.gray[6], textAlign: 'center' }}
          >
            <Skeleton variant="text" sx={{ width: '100px', m: 'auto' }} />
          </Typography>
        </Stack>
        <Stack>
          <Box className="img--container">
            <Skeleton variant="rectangular" />
          </Box>
        </Stack>

        <Typography
          variant="body"
          sx={{
            color: colors.gray[9],
            '.MuiSkeleton-root': { borderRadius: 1 },
          }}
        >
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Typography>
      </Stack>
    </PageContent>
  )
}

export default ArticleSkeleton
