import { faRectangleXmark } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Modal, ModalProps, Stack, Typography } from '@mui/material'
import { ThemeModalProps } from '../../types'
import { useTranslation } from 'react-i18next'
import { colors } from '../../colors'

const ThemeModal = ({
  maxWidth = 420,
  ...props
}: ThemeModalProps & ModalProps) => {
  return (
    <Modal
      open={props.open}
      // onClose={props.onClose}
      sx={{ display: 'grid', placeItems: 'center', m: 2.5 }}
    >
      <Stack
        borderRadius={4}
        minWidth={340}
        maxWidth={maxWidth}
        width="100%"
        sx={{
          background: 'linear-gradient(180deg, #1D1929 0%, #2D2740 100%)',
          p: 2,
          overflow: 'scroll',
          ...props.sx,
        }}
      >
        {props.title && <ModalHeader {...props} />}
        <Stack flex={1} mt={2}>
          {props.children}
        </Stack>
      </Stack>
    </Modal>
  )
}

const ModalHeader = (props: ThemeModalProps & ModalProps) => {
  const { t } = useTranslation()
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      color={colors.gray[10]}
      gap={1}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {props.icon && <FontAwesomeIcon icon={props.icon} fontSize={20} />}
        <Typography variant="headline">{t(props.title)}</Typography>
      </Stack>
      <IconButton
        onClick={(e) => props.onClose && props.onClose(e, 'escapeKeyDown')}
      >
        <FontAwesomeIcon
          icon={faRectangleXmark}
          color={colors.gray[10]}
          fontSize={20}
        />
      </IconButton>
    </Stack>
  )
}

export default ThemeModal
