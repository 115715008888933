import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import B2BContainer from '../B2BContainer/B2BContainer'

interface ProductBanner {
  images: { desktop: string; mobile: string }
  title?: string
}

const ProductBanner = (props: ProductBanner) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <B2BContainer
      sx={{
        img: { position: 'relative', zIndex: 0 },
        flex: 1,
      }}
    >
      {md ? <DesktopBanner {...props} /> : <MobileBanner {...props} />}
    </B2BContainer>
  )
}

const MobileBanner = (props: ProductBanner) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)

  // TODO: Create a function to calculate aspect ratio
  return (
    <Box sx={{ flex: 1 }}>
      <Box
        sx={{
          height: '143.5vw',
          position: 'relative',
          '.MuiSkeleton-root': {
            position: 'absolute',
            inset: 0,
            height: 1,
          },
          '.MuiTypography-root': {
            textAlign: 'center',
            width: 1,
          },
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            display: loading ? 'block' : 'none',
          }}
        />

        {props.images.mobile && (
          <img
            src={`${props.images.mobile}?1`}
            alt=""
            width={'100%'}
            onLoad={() => setLoading(false)}
          />
        )}
      </Box>
      {props.title && (
        <Typography variant={'title3'} textAlign="center" component="p">
          {t(props.title)}
        </Typography>
      )}
    </Box>
  )
}

const DesktopBanner = (props: ProductBanner) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)

  return (
    <Box sx={{ flex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: ['143.5vw', 250, 278, 370, 444],
          '.MuiSkeleton-root': {
            position: 'absolute',
            inset: 0,
            height: 1,
            width: 1,
          },
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            display: loading ? 'block' : 'none',
          }}
        />

        {props.images.desktop && (
          <img
            src={`${props.images.desktop}?1`}
            alt=""
            width={'100%'}
            onLoad={() => setLoading(false)}
          />
        )}
      </Box>
      {props.title && (
        <Typography variant={'title2'} textAlign="center" component="p">
          {t(props.title)}
        </Typography>
      )}
    </Box>
  )
}

export default ProductBanner
