import logo from '../../assets/logo.svg'
import {
  faArrowLeftFromLine,
  faBars,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { LanguageSelect } from '..'
import { colors } from '../../colors'
import { NavItemProps } from '../../types'
import { navList } from './navList'

// TODO: Create separate sx const
export const MobileMenu = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <IconButton
        sx={{
          width: 38,
          height: 38,
          bgcolor: colors.gray[4],
          '&:hover, &:focus': {
            bgcolor: colors.gray[5],
          },
        }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <FontAwesomeIcon icon={faBars} fontSize={12} />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          '.MuiPaper-root': {
            width: 254,
            background: colors.gray[4],
            borderRadius: '0 16px 16px 0',
          },
        }}
      >
        <Stack
          sx={{
            p: 3,
            flex: 1,
            '.MuiListItem-root': { py: 0.25 },
            '.MuiButtonBase-root': { gap: 2, borderRadius: 2, p: 1 },
            '.MuiListItemIcon-root': { minWidth: 0 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              svg: { fontSize: 20 },
            }}
          >
            <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
              <img src={logo} alt="" width={97} />
            </Box>
            <IconButton onClick={() => setOpen(false)}>
              <FontAwesomeIcon icon={faArrowLeftFromLine} fixedWidth />
            </IconButton>
          </Box>
          <List>
            {navList.map((item) => (
              <MobileNavItem
                item={item}
                setOpenNav={setOpen}
                key={item.label}
              />
            ))}
          </List>
          <Stack sx={{ mt: 'auto', gap: 1 }}>
            {/* <GoogleLogin
              onSuccess={authApi}
              onError={() => {
                console.log('Login Failed')
              }}
              shape="pill"
              theme="filled_black"
            /> */}
            <LanguageSelect />
          </Stack>
        </Stack>
      </Drawer>
    </>
  )
}

interface MobileNavItemProps {
  item: NavItemProps
  setOpenNav: Dispatch<SetStateAction<boolean>>
}

export const MobileNavItem = (props: MobileNavItemProps) => {
  const { item, setOpenNav } = props
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const { t, i18n } = useTranslation()
  return (
    <>
      <ListItem
        key={item.href}
        sx={{
          a: {
            color: 'inherit',
            textDecoration: 'none',
            flex: 1,
          },
          '.active': {
            '.MuiButtonBase-root, svg': { color: theme.palette.primary.main },
          },
        }}
      >
        {!item.children ? (
          <NavLink
            to={`${i18n.resolvedLanguage}/${item.href}`}
            className={({ isActive, isPending }) =>
              isPending ? 'pending' : isActive ? 'active' : ''
            }
          >
            <ListItemButton onClick={() => setOpenNav(false)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={item.icon} />
              </ListItemIcon>
              <ListItemText
                primary={t(item.label)}
                primaryTypographyProps={{ variant: 'bodyBold' }}
              />
              {item.children && (
                <FontAwesomeIcon
                  icon={open ? faChevronDown : faChevronRight}
                  fixedWidth
                  color={colors.gray[12]}
                />
              )}
            </ListItemButton>
          </NavLink>
        ) : (
          <ListItemButton onClick={() => setOpen((prev) => !prev)}>
            <ListItemIcon>
              <FontAwesomeIcon icon={item.icon} />
            </ListItemIcon>
            <ListItemText
              primary={t(item.label)}
              primaryTypographyProps={{ variant: 'bodyBold' }}
            />
            {item.children && (
              <FontAwesomeIcon
                icon={open ? faChevronDown : faChevronRight}
                fixedWidth
                color={colors.gray[12]}
              />
            )}
          </ListItemButton>
        )}
      </ListItem>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List dense={true} component="div" disablePadding sx={{ pl: 1.25 }}>
            <Divider
              sx={{ border: `1px dashed ${colors.gray[5]}`, borderRadius: 3 }}
            />
            {item.children.map((item) => (
              <MobileNavItem
                item={item}
                setOpenNav={setOpenNav}
                key={item.label}
              />
            ))}
            <Divider
              sx={{ border: `1px dashed ${colors.gray[5]}`, borderRadius: 3 }}
            />
          </List>
        </Collapse>
      )}
    </>
  )
}
