import {
  Box,
  BoxProps,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material'

const B2BContainer = (props: BoxProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box {...props}>
      <Container
        maxWidth={'xl'}
        sx={{ display: 'flex', position: 'relative', p: '0 !important' }}
        fixed={md}
      >
        {props.children}
      </Container>
    </Box>
  )
}

export default B2BContainer
