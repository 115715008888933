import { gameListApi } from '../../apis/strapiApi'

import { Game } from '../../types'
import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Hero, PageContent, GameCard } from '../../components'
import GameCardSkeleton from '../../components/GameCard/GameCardSkeleton'
import { gcSkeletonList } from '../../constants'
import { ContactUs } from '../../sections'

const Products = () => {
  return (
    <Stack>
      <Helmet>
        <title>Products - iMoon B2B</title>
      </Helmet>
      <Hero />
      <PageContent title="products.title" subtitle="products.subtitle">
        <ProductGrid />
        <ContactUs />
      </PageContent>
    </Stack>
  )
}

export interface Meta {
  pagination: Pagination
}

export interface Pagination {
  page: number
  pageSize: number
  pageCount: number
  total: number
}

const ProductGrid = () => {
  const { t, i18n } = useTranslation()
  const [gameList, setGameList] = useState<Game[]>()

  useEffect(() => {
    const getList = async () => {
      const data = await gameListApi(i18n.resolvedLanguage || 'en')
      setGameList(data?.data)
    }
    getList()
  }, [i18n.resolvedLanguage])

  const gridItem = (children: JSX.Element) => (
    <Grid item xs={6} sm={3} md={3} lg={2} xl={2} key={children.key}>
      {children}
    </Grid>
  )

  return (
    <Box>
      <Grid
        container
        justifyContent={'center'}
        spacing={2}
        columns={14}
        sx={{
          '.MuiPaper-root.MuiCard-root': {
            height: ['calc(57.3vw - 40px)', 189, 227, 201, 201],
          },
        }}
      >
        {gameList
          ? gameList.map((item) =>
              gridItem(<GameCard {...item} key={item.id} />)
            )
          : gcSkeletonList.map((item, index) =>
              gridItem(<GameCardSkeleton key={index} />)
            )}
      </Grid>
      {/* <Button>More Games</Button> */}
    </Box>
  )
}

export default Products
