import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  BoxProps,
  Button,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { colors } from '../../colors'
import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
interface CollectionProps extends PropsWithChildren {
  title: string
  icon: IconDefinition
  subtitle?: string
  viewAllUrl?: string
}

const sx: SxProps<Theme> = {
  position: 'relative',
  '.collection__header': {
    mb: 1.5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.collection__title': {
      flexDirection: 'row',
      alignItems: 'center',
    },
    a: {
      fontSize: 12,
      fontWeight: 700,
      textDecoration: 'none',
      color: colors.gray[8],
      ':hover': { color: 'white' },
    },
  },
}

const Section = (props: CollectionProps & BoxProps) => {
  const { title, icon, subtitle, viewAllUrl } = props
  const { t } = useTranslation()

  const theme = useTheme()
  return (
    <Box sx={{ ...sx, ...props.sx }}>
      <Stack className="collection__header">
        <Box>
          <Stack className="collection__title">
            <FontAwesomeIcon icon={icon} color={theme.palette.primary.main} />
            <Typography
              variant="bodyBold"
              sx={{
                fontSize: 18,
                fontWeight: 900,
                whiteSpace: 'nowrap',
                ml: 1.75,
              }}
            >
              {t(title)}
            </Typography>
          </Stack>
          {subtitle && (
            <Typography variant="subHeadline" sx={{ color: colors.gray[8] }}>
              {t(subtitle)}
            </Typography>
          )}
        </Box>
        {viewAllUrl && (
          <Link to={viewAllUrl}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{
                fontSize: 12,
                color: colors.gray[8],
                fontWeight: 700,
                p: '7.5px 16px',
                border: `1px solid ${colors.gray[5]}`,
                ':hover': {
                  border: `1px solid ${colors.gray[3]}`,
                },
              }}
            >
              {t('section.view_all')}
            </Button>
          </Link>
        )}
      </Stack>
      {props.children}
    </Box>
  )
}

export default Section
