import { levelUpProductListApi } from '../../apis/strapiApi'
import { cdnUrl, gcSkeletonList } from '../../constants'
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Banner from '../../components/Banner/Banner'
import { GameCard } from '../../components/GameCard/GameCard'
import { GameList } from '../NewProductsCollection/NewProductsCollection'
import './styles.scss'
import GameCardSkeleton from '../../components/GameCard/GameCardSkeleton'
import { theme } from '../../theme'
import { Interweave } from 'interweave'

const sx: SxProps<Theme> = {
  display: 'flex',
  border: 'none',
  marginTop: '54px',
  '.MuiStack-root span': {
    color: theme.palette.primary.main,
  },

  '.levelup': {
    '&__title': {
      textAlign: 'center',
      img: {
        marginTop: '-54px',
      },
    },
  },
}

const LevelUpBanner = () => {
  const { t, i18n } = useTranslation()
  const [productList, setProductList] = useState<GameList>()

  useEffect(() => {
    const getList = async () => {
      const data = await levelUpProductListApi(i18n.resolvedLanguage || 'en')
      setProductList(data?.data)
    }
    getList()
  }, [i18n.resolvedLanguage])

  return (
    <Banner sx={sx}>
      <Box
        className="levelup__container"
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          gap: 1.5,
          justifyContent: 'space-between',
          alignItems: 'center',
          span: { color: theme.palette.primary.main },
          '.levelup__planet': {
            rotate: '-34deg',
            animation: 'bop 1s infinite ease-in-out',
          },
        }}
      >
        <Stack className="levelup__title">
          <Box>
            <img
              src={`${cdnUrl}home/planet.png`}
              alt="planet"
              className="levelup__planet"
            />
          </Box>
          <Typography variant="h5" fontWeight={800}>
            <span>{t('home.level_up_banner.level_up')}</span>{' '}
            {t('home.level_up_banner.your_business')}
          </Typography>
          <Typography variant="h5" fontWeight={800} fontSize={34}>
            {t('home.level_up_banner.with')}
            <span> {t('home.level_up_banner.imoon')}</span>{' '}
            {t('home.level_up_banner.games')}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          gap={2}
          display={'flex'}
          sx={{ '.MuiCard-root': { height: 'auto !important' } }}
        >
          {productList
            ? productList.attributes?.games.data.map((gameCard, index) => (
                <Box
                  sx={{
                    display: { xs: index >= 2 ? 'none' : 'block', md: 'block' },
                    '.MuiPaper-root.MuiCard-root': {
                      width: [1, 174, 125, 174],
                    },
                  }}
                  key={gameCard.id}
                >
                  <GameCard {...gameCard} />
                </Box>
              ))
            : gcSkeletonList.map((gameCard, index) => (
                <Box
                  sx={{
                    display: { xs: index >= 2 ? 'none' : 'block', md: 'block' },
                  }}
                  key={index}
                >
                  <GameCardSkeleton />
                </Box>
              ))}
        </Stack>
      </Box>
    </Banner>
  )
}

export default LevelUpBanner
