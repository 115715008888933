import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

// import required modules
import { Mousewheel, FreeMode } from 'swiper'

interface ImageSliderProps {
  imgUrlList: string[]
}

// TODO: Use vertical slider for desktop
export const ImageSlider = (props: ImageSliderProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  const [mainImg, setMainImg] = useState(props.imgUrlList[0])

  return (
    <Stack
      sx={{
        flexDirection: { xs: 'column', md: 'row' },

        '.main-img, img': {
          flex: 1,
          height: 'auto',
        },
        '.swiper': {
          height: { xs: 'auto', md: 377, lg: 528 },
          '.swiper-wrapper': {
            '& > *:not(:last-child)': {
              mb: { md: 2 },
              marginInlineEnd: { xs: 2, md: 0 },
            },
            img: {
              width: [1, 1, 140, 165],
            },
            '.MuiBox-root': {
              cursor: 'pointer',
              display: 'flex',
            },
          },
        },
      }}
    >
      <Box sx={{ flex: 1, marginInlineEnd: { md: 3 } }}>
        <img src={mainImg} alt="iMoon booth" className="slider-img--main" />
      </Box>
      <Box>
        <Swiper
          modules={[Mousewheel, FreeMode]}
          mousewheel
          freeMode
          direction={md ? 'vertical' : 'horizontal'}
          breakpoints={{
            0: {
              slidesPerView: 3,
            },
            [theme.breakpoints.values.sm]: {
              slidesPerView: 4.5,
            },

            [theme.breakpoints.values.lg]: {
              slidesPerView: 6,
            },
          }}
        >
          {props.imgUrlList.map((item, index) => (
            <SwiperSlide key={index}>
              <Box
                onClick={() => setMainImg(item)}
                sx={{
                  ':hover': { opacity: 0.8 },
                  height: '100% !important',
                }}
              >
                <img src={item} alt="" />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Stack>
  )
}
