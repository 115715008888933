import axios from 'axios'
import { FormData } from '../components/ContactForm/ContactForm'
export interface SuccessResponseData {
  success: boolean
}

export const contactFormApi = async (
  // recaptchaToken: string,
  formData: FormData
) => {
  const data = JSON.stringify({
    data: formData,
  })

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_ALIEN_OPERATOR_BASE_API_PATH}user/contact-us`,
    headers: {
      'Content-Type': 'application/json',
      // recaptcha: recaptchaToken,
    },
    data,
  }

  console.log('form data::', formData)
  try {
    const res = await axios(config)
    console.log('response::', res)
    return true
  } catch (error) {
    console.error(error)
    return
  }
}
