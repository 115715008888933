import backgroundImg from '../../assets/contactus/background.jpg'
import {
  Box,
  Container,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ContactForm } from '../../components'
const ContactUs = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  const { t, i18n } = useTranslation()

  const { locale } = useParams()

  useEffect(() => {
    if (locale !== i18n.resolvedLanguage) i18n.changeLanguage(locale)
  }, [locale])

  return (
    <Box
      sx={{
        position: 'relative',
        background: `url(${backgroundImg}) no-repeat bottom center`,
      }}
    >
      <Helmet>
        <title>Contact Us - iMoon B2B</title>
      </Helmet>
      <Toolbar />
      <Container fixed maxWidth={'md'}>
        <Stack
          sx={{
            minHeight: 720,
            justifyContent: 'space-between',
            pb: 8,
          }}
        >
          <Typography variant={md ? 'title2' : 'title3'} textAlign={'center'}>
            {t('contact_us.title')}
          </Typography>
          <ContactForm />
        </Stack>
      </Container>
    </Box>
  )
}

export default ContactUs
