import {
  Box,
  Container,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { HeroBanner } from '../../../components'
import { cdnUrl } from '../../../constants'
import { colors } from '../../../colors'

const path = `${cdnUrl}company/tournaments/`

const Tournaments = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <Box
      sx={{
        '.img-container': {
          position: 'relative',
          width: 1,
          img: { position: 'relative', width: 1, zIndex: 1, borderRadius: 3 },

          '.MuiSkeleton-root': {
            position: 'absolute',
            inset: 0,
            height: 1,
            borderRadius: 3,
          },
        },
      }}
    >
      <Helmet>
        <title>Tournament - iMoon B2B</title>
      </Helmet>
      <HeroBanner
        images={{
          desktop: `${path}banner.jpg`,
          mobile: `${path}banner_mobile.jpg`,
        }}
        title="tournaments.title"
      />
      <Container fixed>
        <Stack
          gap={[1.5, 1.5, 3]}
          sx={{
            '.MuiTypography-root': {
              color: colors.gray[9],
              fontWeight: 400,
            },
            mb: 6,
          }}
        >
          <Typography
            variant="subHeadline"
            sx={{ fontWeight: '700 !important' }}
          >
            {t('tournaments.overview.p1')}
          </Typography>
          <Stack
            sx={{
              flexDirection: ['column', null, 'row'],
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 4,
            }}
          >
            <Stack sx={{ gap: 2, maxWidth: { md: 550 } }}>
              <Typography>{t('tournaments.overview.p2')}</Typography>
              <Typography>{t('tournaments.overview.p3')}</Typography>
            </Stack>
            <Box
              className="img-container"
              sx={{
                height: ['calc(78vw  - 32px)', 380, 216, 380, 380],
                maxWidth: 471,
                maxHeight: 380,
                mb: { xs: 2, md: 0 },
              }}
            >
              <Skeleton variant="rectangular" />
              <img src={`${path}1.jpg?1`} alt="" />
            </Box>
          </Stack>
          <Typography>{t('tournaments.overview.p4')}</Typography>
          <Box
            className="img-container"
            sx={{
              height: ['calc(122vw  - 40px)', 236, 263, 367, 367],
              '.MuiSkeleton-root': {
                borderRadius: '24px !important',
              },
              img: { borderRadius: '24px' },
            }}
          >
            <Skeleton variant="rectangular" />
            <img src={`${path}${md ? '2' : '2_mobile'}.jpg`} alt="" />
          </Box>
          <Typography>{t('tournaments.overview.p5')}</Typography>
          <Stack
            sx={{
              flexDirection: ['column', null, 'row'],
              alignItems: 'center',
              gap: 4,
              justifyContent: 'space-around',
            }}
          >
            <Box
              className="img-container"
              sx={{
                height: { xs: 205, md: 110, lg: 205 },
                maxWidth: 268,
              }}
            >
              <Skeleton variant="rectangular" />
              <img src={`${path}3.jpg?1`} alt="" />
            </Box>
            <Typography sx={{ maxWidth: { lg: 765 } }}>
              {t('tournaments.overview.p6')}
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Tournaments
