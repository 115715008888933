import { faArrowUpRight } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Card,
  CardContent,
  CardProps,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { colors } from '../../colors'
import { removeHtmlTags } from '../../helpers/helpers'
import { Article } from '../../types'

export interface ArticleCardProps extends Article {
  orientation?: 'horizontal' | 'vertical'
  size?: 'small' | 'medium'
}

export const ArticleCard = (props: ArticleCardProps & CardProps) => {
  const { attributes } = props
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { locale } = useParams()
  const [loading, setLoading] = useState(true)

  return (
    <Card
      sx={{
        bgcolor: colors.gray[3],
        width: 1,
        borderRadius: 3,
        display: 'flex',
        flexDirection: props.orientation === 'horizontal' ? 'row' : 'column',

        '.news__card--img': {
          height:
            props.orientation === 'horizontal'
              ? [null, 285.6, 267.5, 404.2]
              : props.size === 'small'
              ? ['24.3vw', 150]
              : ['45vw', 193.1, 149.7, 204.5],
          img: {
            zIndex: 1,
            height: 1,
          },
          '.MuiSkeleton-root': {
            position: 'absolute',
            inset: 0,
            height: 1,
            display: loading ? 'initial' : 'none',
          },
        },

        '.news__card--content': {
          display: 'flex',
          flexDirection: 'column',
          flex: attributes?.content ? 'auto' : '1',
          p: props.size === 'small' ? 1.5 : 2,
        },

        ...props.sx,
      }}
    >
      {attributes && (
        <>
          <Stack
            sx={{
              position: 'relative',
              width: '100%',
              borderRadius: '12px 12px 0 0',
              img: {
                flex: 1,
              },
            }}
            className="news__card--img"
          >
            <Skeleton
              variant="rectangular"
              sx={{
                height: 1,
                position: 'absolute',
                inset: 0,
                display: loading ? 'initial' : 'none',
              }}
            />
            {attributes.assets?.data?.[0].attributes.url && (
              <img
                src={attributes.assets?.data?.[0].attributes.url}
                alt=""
                onLoad={() => setLoading(false)}
              />
            )}
          </Stack>

          <CardContent className="news__card--content">
            {props.size === 'small' ? (
              <Stack sx={{ gap: 0.5 }}>
                <Stack>
                  <Typography
                    variant={'bodySmallBold'}
                    sx={{
                      height: '32px',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      whiteSpace: 'initial',
                    }}
                    className="hide-overflow"
                  >
                    {attributes.title}
                  </Typography>
                </Stack>
                <Typography variant="caption" sx={{ color: colors.gray[6] }}>
                  {attributes.date ? (
                    dayjs(attributes.date).format('MMM D, YYYY')
                  ) : (
                    <Skeleton variant="text" sx={{ width: 74 }} />
                  )}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    navigate(
                      `/${i18n.resolvedLanguage}/news/${attributes.slug}`
                    )
                  }
                  sx={{
                    width: 32,
                    minWidth: 'auto',
                    alignSelf: 'flex-end',
                    mt: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faArrowUpRight} />
                </Button>
              </Stack>
            ) : (
              <>
                <Stack>
                  <Typography
                    variant={'subHeadline'}
                    sx={{
                      height: '48px',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      whiteSpace: 'initial',
                    }}
                    className="hide-overflow"
                  >
                    {attributes.title || (
                      <Skeleton variant="text" sx={{ height: '48px' }} />
                    )}
                  </Typography>
                  <Typography
                    className="news__card--excerpt"
                    variant="body"
                    sx={{
                      display: '-webkit-box',
                      height: 74,
                      color: colors.gray[8],
                      opacity: 0.6,
                      maxWidth: { md: 500 },
                      lineHeight: '24px',
                      WebkitLineClamp: 3,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitBoxOrient: 'vertical',
                      whiteSpace: 'initial',
                    }}
                  >
                    {attributes.content ? (
                      removeHtmlTags(attributes.content)
                    ) : (
                      <Skeleton variant="text" sx={{ height: 90 }} />
                    )}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pt: 3,
                    mt: 'auto',
                  }}
                >
                  <Typography variant="caption" sx={{ color: colors.gray[6] }}>
                    {attributes.date ? (
                      dayjs(attributes.date).format('MMM D, YYYY')
                    ) : (
                      <Skeleton variant="text" sx={{ width: 74 }} />
                    )}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      navigate(`/${locale}/news/${attributes.slug}`)
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    {t('general.read_more')}
                  </Button>
                </Stack>
              </>
            )}
          </CardContent>
        </>
      )}
    </Card>
  )
}
