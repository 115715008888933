import { ThemeProvider } from '@emotion/react'
import { Box, CssBaseline, Typography } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { SnackbarProvider } from 'notistack'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom'
import { Layout } from './components'
import ArticleDetails from './components/ArticleDetails/ArticleDetails'
import InfoModal from './components/InfoModal/InfoModal'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Careers from './pages/Careers/Careers'
import WebGameDeveloper from './pages/Careers/OpenPositions/1_WebGameDeveloper'
import SeniorBackendDeveloper from './pages/Careers/OpenPositions/2_SeniorBackendDeveloper'
import FullStackDeveloper from './pages/Careers/OpenPositions/3_FullStackDeveloper'
import BackendDeveloper from './pages/Careers/OpenPositions/5_BackendDeveloper'
import FrontendDeveloper from './pages/Careers/OpenPositions/6_FrontendDeveloper'
import QASpecialist from './pages/Careers/OpenPositions/7_QASpecialist'
import Licenses from './pages/Company/Licenses/Licenses'
import ProvablyFair from './pages/Company/ProvablyFair/ProvablyFair'
import Tournaments from './pages/Company/Tournaments/Tournaments'
import News from './pages/News/News'
import Play from './pages/Play/Play'
import Details from './pages/Products/Details/Details'
import Products from './pages/Products/Products'
import ProfileItem from './pages/ProfileList/ProfileItem'
import { theme } from './theme'
import { useState } from 'react'
import Home from './pages/Home/Home'
import './App.scss'
import ContactUs from './pages/ContactUs/ContactUs'

export function App() {
  const [test, settest] = useState(false)
  return (
    <GoogleOAuthProvider
      clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={`${process.env.REACT_APP_GRECAPTCHA_SITE_KEY}`}
      >
        <ThemeProvider theme={theme}>
          <SnackbarProvider autoHideDuration={5000} disableWindowBlurListener>
            <CssBaseline enableColorScheme />
            <BrowserRouter>
              <Routes>
                <Route
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route path="/" element={<Navigate to={`/en`} replace />} />

                  <Route path=":locale">
                    <Route index element={<Home />} />
                    <Route path="products">
                      <Route index element={<Products />} />
                      <Route path=":product" element={<Details />} />
                    </Route>
                    <Route path="company">
                      <Route path="provably-fair" element={<ProvablyFair />} />
                      <Route path="licenses" element={<Licenses />} />
                      <Route path="tournaments" element={<Tournaments />} />
                    </Route>

                    <Route path="careers">
                      <Route index element={<Careers />}></Route>
                      <Route
                        path="web-game-developer"
                        element={<WebGameDeveloper />}
                      ></Route>
                      <Route
                        path="senior-back-end-developer"
                        element={<SeniorBackendDeveloper />}
                      ></Route>
                      <Route
                        path="full-stack-developer"
                        element={<FullStackDeveloper />}
                      ></Route>
                      {/* <Route
                        path="ui-ux-designer-graphic-artist"
                        element={<UXUIDesigner />}
                      ></Route> */}
                      <Route
                        path="back-end-developer"
                        element={<BackendDeveloper />}
                      ></Route>
                      <Route
                        path="front-end-developer"
                        element={<FrontendDeveloper />}
                      ></Route>
                      <Route
                        path="qa-specialist"
                        element={<QASpecialist />}
                      ></Route>
                    </Route>
                    <Route path="contact-us" element={<ContactUs />}></Route>
                    <Route path="news">
                      <Route index element={<News />}></Route>
                      <Route
                        path=":article"
                        element={<ArticleDetails />}
                      ></Route>
                      <Route path={'*'} element={<Home />} />
                    </Route>
                    <Route path="play">
                      <Route path=":slug">
                        <Route index element={<Play />} />
                        <Route path=":gameId" element={<Play />} />
                      </Route>
                    </Route>
                    <Route path="qr">
                      {/* <Route index element={<ProfileList />} /> */}
                      <Route path=":profileName" element={<ProfileItem />} />
                    </Route>
                  </Route>

                  <Route path={'*'} element={<Navigate to={`/en`} replace />} />
                  {/* <Route path="*" element={<Page404 />} /> */}
                </Route>
                <Route>
                  <Route path="/" element={<Navigate to={`/en`} replace />} />
                  <Route path=":locale">
                    <Route path="infoModal" element={<InfoModal />}></Route>
                  </Route>
                </Route>
              </Routes>
              <ScrollToTop />
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </GoogleOAuthProvider>
  )
}
export default App
