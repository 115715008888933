import {
  faBriefcase,
  faFileCertificate,
  faGamepadModern,
  faHouse,
  faNewspaper,
  faPlanetRinged,
  faShieldCheck,
  faTrophy,
} from '@fortawesome/pro-duotone-svg-icons'
import { NavItemProps } from '../../types'

export const navList: NavItemProps[] = [
  {
    href: '',
    label: 'nav.home',
    icon: faHouse,
  },
  {
    href: 'products',
    label: 'nav.products',
    icon: faGamepadModern,
  },
  // {
  //   href: 'clientHub',
  //   label: 'Client Hub',
  //   icon: faServer,
  // },
  {
    href: '/',
    label: 'nav.company',
    icon: faPlanetRinged,
    children: [
      {
        href: 'company/provably-fair',
        label: 'nav.provably_fair',
        icon: faShieldCheck,
      },
      {
        href: 'company/tournaments',
        label: 'nav.tournaments',
        icon: faTrophy,
      },
      {
        href: 'company/licenses',
        label: 'nav.licenses',
        icon: faFileCertificate,
      },
      // {
      //   href: 'about',
      //   label: 'About Us',
      //   icon: faShieldCheck,
      // },
    ],
  },
  {
    href: 'careers',
    label: 'nav.careers',
    icon: faBriefcase,
  },
  {
    href: 'news',
    label: 'nav.news',
    icon: faNewspaper,
  },
]
