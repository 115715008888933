import { faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { licenseListApi } from '../../../apis/strapiApi'
import { HeroBanner } from '../../../components'
import { cdnUrl } from '../../../constants'
import { License } from '../../../types'
import { colors } from '../../../colors'

const path = `${cdnUrl}company/licenses/`
// interface License {
//   title: string
//   subtitle: string
//   logo: string
//   licenseCode: string
//   src?: JSX.Element
// }<

const getAntilleLicense = () => {
  const antAnchor: HTMLAnchorElement | null = document.querySelector(
    '#apg-f5200ddd-281d-4644-b29b-899f07790f01 a'
  )
  let url = '#'
  if (antAnchor) {
    url = antAnchor.href
    console.log('url::', url)
    const link: HTMLAnchorElement | null =
      document.querySelector('#antilleLink')

    if (link) link.href = url
  }

  return url
}

const Licenses = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))

  const [licenseList, setLicenseList] = useState<License[]>()

  useEffect(() => {
    setTimeout(() => {
      getAntilleLicense()
    }, 1000)
  }, [])

  useEffect(() => {
    const getLicenseList = async () => {
      const data = await licenseListApi(i18n.resolvedLanguage || 'en')
      setLicenseList(data?.data)
    }
    getLicenseList()
  }, [i18n.resolvedLanguage])

  // const licenseList: License[] = [
  //   {
  //     title: 'Malta',
  //     subtitle: 'Malta Gaming Authority',
  //     logo: 'mga',
  //     licenseCode: 'Coming Soon',
  //   },
  //   {
  //     title: 'United Kingdom',
  //     subtitle: 'UK Gambling Commission',
  //     logo: 'gambling',
  //     licenseCode: 'Coming Soon',
  //   },
  //   {
  //     title: 'Romania',
  //     subtitle: 'Romania National Gambling Office',
  //     logo: 'onjn',
  //     licenseCode: 'Coming Soon',
  //   },
  //   {
  //     title: 'BMM',
  //     subtitle: 'bmm Testlabs',
  //     logo: 'bmm',
  //     licenseCode: 'SAB.16G.1019.01',
  //   },
  //   {
  //     title: 'GLI',
  //     subtitle: 'Gaming Laboratories International',
  //     logo: 'gli',
  //     licenseCode: 'Coming Soon',
  //   },
  //   {
  //     title: 'Antillephone',
  //     subtitle: 'Curacao Gambling License',
  //     logo: 'antillephone',
  //     licenseCode: 'iMoon B.V.',
  //     src: (
  //       <Link
  //         id="antilleLink"
  //         sx={{
  //           cursor: 'pointer',
  //           '#apg-f5200ddd-281d-4644-b29b-899f07790f01': { display: 'none' },
  //         }}
  //         target="_blank"
  //       >
  //         <div
  //           id="apg-f5200ddd-281d-4644-b29b-899f07790f01"
  //           data-apg-seal-id="f5200ddd-281d-4644-b29b-899f07790f01"
  //           data-apg-image-size="64"
  //           data-apg-image-type="basic-light-large"
  //         ></div>
  //         View
  //       </Link>
  //     ),
  //   },
  //   {
  //     title: 'iTech Malta',
  //     subtitle: 'Games RNG Certificate',
  //     logo: 'itech',
  //     licenseCode: 'View',
  //     src: (
  //       <Link href={`${path}itech_malta.jpg`} target="_blank">
  //         View
  //       </Link>
  //     ),
  //   },
  //   {
  //     title: 'iTech UK',
  //     subtitle: 'Games RNG Certificate',
  //     logo: 'itech',
  //     licenseCode: 'View',
  //     src: (
  //       <Link href={`${path}itech_uk.jpg`} target="_blank">
  //         View
  //       </Link>
  //     ),
  //   },
  // ]

  return (
    <Box
      sx={{
        a: { color: theme.palette.primary.main, textDecoration: 'underline' },
      }}
    >
      <Helmet>
        <script
          type="text/javascript"
          src="https://f5200ddd-281d-4644-b29b-899f07790f01.snippet.antillephone.com/apg-seal.js"
        ></script>
        <title>Licenses - iMoon B2B</title>
      </Helmet>
      <HeroBanner
        images={{
          desktop: `${path}banner.jpg`,
          mobile: `${path}banner_mobile.jpg`,
        }}
        title="licenses.title"
      />
      <Container sx={{ textAlign: 'center' }} fixed>
        <Stack gap={[1.5, 1.5, 3]} mb={6}>
          <Typography
            variant="subHeadline"
            sx={{ color: colors.gray[9], fontWeight: 400 }}
          >
            {t('licenses.overview.p1')}
          </Typography>
          <Typography variant="headline">
            {t('licenses.overview.sub_headline')}
          </Typography>
          {licenseList &&
            licenseList.map((license) => (
              <LicenseCard {...license} key={license.id} />
            ))}
          <AntilleLicenseCard />
        </Stack>
      </Container>
    </Box>
  )
}

const AntilleLicenseCard = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Stack
      gap={1.5}
      sx={{
        '.MuiPaper-root': {
          background: colors.gray[3],
          border: `1px solid ${colors.gray[3]}`,
          borderRadius: md ? '12px !important' : 'initial',
          textAlign: 'left',
        },
        '.MuiTypography-root': { textAlign: 'left' },
      }}
    >
      {md ? (
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Stack flex={1.4}>
                <Typography variant="bodyBold">Antillephone</Typography>
                <Typography
                  variant="bodySmall"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Curacao Gambling License
                </Typography>
              </Stack>
              <Stack flex={1} sx={{ img: { width: [73, 100, 126] } }}>
                <img src={`${path}antillephone.png`} alt="" />
              </Stack>
              <Typography variant="bodySmall" flex={1}>
                <Link
                  id="antilleLink"
                  sx={{
                    cursor: 'pointer',
                    '#apg-f5200ddd-281d-4644-b29b-899f07790f01': {
                      display: 'none',
                    },
                  }}
                  target="_blank"
                >
                  <div
                    id="apg-f5200ddd-281d-4644-b29b-899f07790f01"
                    data-apg-seal-id="f5200ddd-281d-4644-b29b-899f07790f01"
                    data-apg-image-size="64"
                    data-apg-image-type="basic-light-large"
                  ></div>
                  View
                </Link>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Accordion>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faPlus} />}
            sx={{
              '.MuiPaper-root': {
                background: colors.gray[2],
                textAlign: 'left',
              },
            }}
          >
            <Stack flex={1.4}>
              <Typography variant="bodyBold">Antillephone</Typography>
              <Typography
                variant="bodySmall"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: 150,
                }}
              >
                Curacao Gambling License
              </Typography>
            </Stack>
            <Stack
              flex={1}
              sx={{ img: { width: [75, 100, 120] }, justifyContent: 'center' }}
            >
              <img src={`${path}antillephone.png`} alt="" />
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              textAlign: 'center',
              background: colors.gray[4],
              borderRadius: '0 0 3px 3px',
            }}
          >
            <Typography variant="bodySmall" flex={1}>
              <Link
                id="antilleLink"
                sx={{
                  cursor: 'pointer',
                  '#apg-f5200ddd-281d-4644-b29b-899f07790f01': {
                    display: 'none',
                  },
                }}
                target="_blank"
              >
                <div
                  id="apg-f5200ddd-281d-4644-b29b-899f07790f01"
                  data-apg-seal-id="f5200ddd-281d-4644-b29b-899f07790f01"
                  data-apg-image-size="64"
                  data-apg-image-type="basic-light-large"
                ></div>
                View
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </Stack>
  )
}

const LicenseCard = (props: License) => {
  const { attributes } = props
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))

  useEffect(() => {
    const licenseCodeDom = document.querySelectorAll(
      `._${props.id}__licenseCode`
    )
    if (licenseCodeDom)
      licenseCodeDom.forEach((item) => {
        if (attributes) item.innerHTML = attributes?.licenseCode
      })
  }, [md])

  if (!attributes) return <></>

  return (
    <Stack
      gap={1.5}
      sx={{
        '.MuiPaper-root': {
          background: colors.gray[3],
          border: `1px solid ${colors.gray[3]}`,
          borderRadius: md ? '12px !important' : 'initial',
          textAlign: 'left',
        },
        '.MuiTypography-root': { textAlign: 'left' },
      }}
    >
      {md ? (
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Stack flex={1.4}>
                <Typography variant="bodyBold">{attributes.title}</Typography>
                <Typography
                  variant="bodySmall"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {attributes.subtitle}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                sx={{
                  'img, .MuiSkeleton-root': { width: [73, 100, 126] },
                  '.MuiSkeleton-root': {
                    position: 'absolute',
                    height: [26, 34, 43],
                  },
                }}
              >
                {attributes.logo.data?.attributes.url ? (
                  <img src={attributes.logo.data?.attributes.url} alt="" />
                ) : (
                  <Skeleton variant="rectangular" />
                )}
              </Stack>
              <Typography variant="bodySmall" flex={1}>
                <Box className={`_${props.id}__licenseCode`}></Box>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Accordion>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faPlus} />}
            sx={{
              '.MuiPaper-root': {
                background: colors.gray[2],
                textAlign: 'left',
              },
            }}
          >
            <Stack flex={1.4}>
              <Typography variant="bodyBold">{attributes.title}</Typography>
              <Typography
                variant="bodySmall"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: 150,
                }}
              >
                {attributes.subtitle}
              </Typography>
            </Stack>
            <Stack
              flex={1}
              sx={{
                'img, .MuiSkeleton-root': { width: [73, 100, 126] },
                '.MuiSkeleton-root': {
                  position: 'absolute',
                  height: [26, 34, 43],
                },
                justifyContent: 'center',
              }}
            >
              {attributes.logo.data?.attributes.url ? (
                <img src={attributes.logo.data?.attributes.url} alt="" />
              ) : (
                <Skeleton variant="rectangular" />
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              textAlign: 'center',
              background: colors.gray[4],
              borderRadius: '0 0 3px 3px',
            }}
          >
            <Typography variant="bodySmall" flex={1}>
              <Box
                className={`_${props.id}__licenseCode`}
                sx={{ textAlign: 'center' }}
              ></Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </Stack>
  )
}

export default Licenses
