import { createTheme } from '@mui/material'
import { orange } from '@mui/material/colors'
import { colors, gradients } from './colors'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  color: colors,
  gradients: gradients,
  typography: {
    fontFamily: '"Nunito Sans", sans-serif', // React fonts
    fontSize: 16,
  },
  status: {
    danger: orange[500],
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.primary[4],
      light: '#F78E2C',
      dark: colors.primary[3],
      contrastText: '#000',
    },
    secondary: {
      main: colors.secondary[5], // Buttons main color
      light: colors.secondary[8],
      dark: colors.secondary[4],
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: colors.gray[4],
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 24px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiInput-root': { background: ' #110C20 !important' },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderRadius: 12,
          padding: '10px 20px',
          textTransform: 'capitalize',
        },
        sizeSmall: {
          padding: '6px 16px',
        },
        outlined: {
          borderWidth: 2,
          '&:hover, &:focus': {
            borderWidth: 2,
            background: '#f9f9f911',
          },
        },

        outlinedSecondary: {
          border: `1px solid ${colors.gray[11]}`,
          color: colors.primary[4],

          '&:hover, &:focus': {
            borderWidth: 1,
            border: `1px solid ${colors.gray[11]}`,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.gray[4]}`,
          borderRadius: '12px !important',
          background: colors.gray[2],
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'title1' },
          style: {
            fontWeight: 700,
            fontSize: '60px',
            lineHeight: '120%',
          },
        },
        {
          props: { variant: 'title2' },
          style: {
            fontWeight: 700,
            fontSize: '48px',
            lineHeight: '116%',
          },
        },
        {
          props: { variant: 'title3' },
          style: {
            fontWeight: 800,
            fontSize: '34px',
            lineHeight: '117%',
          },
        },
        {
          props: { variant: 'headline' },
          style: {
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '117%',
            letterSpacing: '0.01em',
            color: colors.gray[8],
          },
        },
        {
          props: { variant: 'subHeadline' },
          style: {
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '135%',
            letterSpacing: '0.01em',
          },
        },
        {
          props: { variant: 'body' },
          style: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '147%',
            color: colors.gray[9],
          },
        },
        {
          props: { variant: 'bodySmall' },
          style: {
            color: colors.gray[8],
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '116%',
          },
        },
        {
          props: { variant: 'bodySmallBold' },
          style: {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '116%',
          },
        },
        {
          props: { variant: 'bodyMedium' },
          style: {
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '147%',
          },
        },
        {
          props: { variant: 'bodyBold' },
          style: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '147%',
          },
        },
        {
          props: { variant: 'caption' },
          style: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '130%',
          },
        },
        {
          props: { variant: 'buttonLarge' },
          style: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '150%',
          },
        },
        {
          props: { variant: 'buttonMedium' },
          style: {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '112%',
          },
        },
        {
          props: { variant: 'buttonSmall' },
          style: {
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '130%',
          },
        },
      ],
    },
  },
})

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title1: true
    title2: true
    title3: true
    headline: true
    subHeadline: true
    body: true
    bodyBold: true
    bodySmall: true
    bodySmallBold: true
    bodyMedium: true
    bodyLarge: true
    buttonLarge: true
    buttonMedium: true
    buttonSmall: true
  }
}

declare module '@mui/material/styles/createPalette' {}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
    white: true
    gray: true
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string
    }
    color: {
      gray: {}
      primary: {}
      secondary: {}
      tertiary: {}
    }
    gradients: {}
  }

  interface ThemeOptions {
    status?: {
      danger?: string
    }
    color: {
      gray: {}
      primary: {}
      secondary: {}
      tertiary: {}
    }
    gradients: {}
  }
}
