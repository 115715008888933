import { faArrowUpRight } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { colors } from '../../colors'
import positionList from './OpenPositions/positionList.json'
import { PageContent } from '../../components'
import { slugify } from '../../helpers/helpers'

const Careers = () => {
  return (
    <PageContent title="careers.title">
      <Helmet>
        <title>Careers - iMoon B2B</title>
      </Helmet>
      {/* TODO: Add filters */}
      <OpenPositionsTable />
    </PageContent>
  )
}

const OpenPositionsTable = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  return (
    <TableContainer
      sx={{
        minHeight: 500,
        '.MuiTableCell-head': {
          color: colors.gray[7],
          borderColor: colors.gray[6],
        },

        '.MuiTableBody-root': {
          background: colors.gray[4],
          '.MuiTableRow-root ': {
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8,
            },
          },
        },

        '.MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)': {
          background: colors.gray[3],
        },

        '.MuiTableCell-body': {
          border: 'none',
        },
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('careers.table.header_one')}</TableCell>
            {md && (
              <>
                <TableCell>{t('careers.table.header_two')}</TableCell>
                <TableCell>{t('careers.table.header_three')}</TableCell>
              </>
            )}
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows().map((row) => (
            <TableRow
              key={row.id}
              onClick={() => navigate(slugify(row.jobTitle))}
            >
              <TableCell
                sx={{ color: theme.palette.primary.main, fontWeight: 700 }}
              >
                {row.jobTitle}
              </TableCell>
              {md && (
                <>
                  <TableCell>{row.department}</TableCell>
                  <TableCell sx={{ color: colors.gray[8], fontWeight: 400 }}>
                    {row.category}
                  </TableCell>
                </>
              )}
              <TableCell align="right">
                <IconButton
                  sx={{ width: 32, height: 32 }}
                  onClick={() => navigate(slugify(row.jobTitle))}
                >
                  <FontAwesomeIcon
                    icon={faArrowUpRight}
                    fixedWidth
                    fontSize={16}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const rows = () => {
  return positionList.map((item, index) => ({
    id: index,
    category: '',
    department: '',
    jobTitle: item.jobTitle,
  }))
}

export default Careers
