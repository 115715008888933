import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { HowItWorksCards } from './HowItWorksCards'
import { colors } from '../../../colors'
import { HeroBanner } from '../../../components'
import { cdnUrl } from '../../../constants'

const path = `${cdnUrl}company/provably-fair/`

const ProvablyFair = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <Helmet>
        <title>Provably Fair - iMoon B2B</title>
      </Helmet>
      <HeroBanner
        images={{
          desktop: `${path}banner.jpg`,
          mobile: `${path}banner_mobile.jpg`,
        }}
        title="provably_fair.title"
      />
      <Container fixed sx={{ textAlign: 'center' }}>
        <Stack gap={[1.5, 1.5, 3]} mb={6}>
          <Typography
            variant="subHeadline"
            sx={{ color: colors.gray[9], fontWeight: 400 }}
          >
            {t('provably_fair.overview.p1')}
          </Typography>
          <Typography variant="headline">
            {t('provably_fair.overview.sub_headline')}
          </Typography>
          <Stack gap={1.5} sx={{ textAlign: 'left' }}>
            <FairnessAccordion
              title="provably_fair.how_it_works.title"
              content={<HowItWorks />}
            />
            <FairnessAccordion
              title="provably_fair.how_to_verify.title"
              content={<HowToVerify />}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

interface FairnessAccordionProps {
  title: string
  content: JSX.Element
}

const FairnessAccordion = (props: FairnessAccordionProps) => {
  const { t } = useTranslation()

  return (
    <Accordion
      sx={{
        background: colors.gray[3],
        border: `1px solid ${colors.gray[4]}`,
        '&': {
          borderRadius: '12px !important',
        },
        '&::before': {
          display: 'none !important',
        },
        '.MuiAccordionDetails-root': {
          background: colors.gray[4],
          borderRadius: '0 0 12px 12px',
        },
      }}
    >
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
        <Typography variant="bodyBold">{t(props.title)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack sx={{ gap: 3, p: 2 }}>{props.content}</Stack>
      </AccordionDetails>
    </Accordion>
  )
}

// TODO: Develop on how it works cards
const HowItWorks = () => {
  const { t } = useTranslation()

  const textList = [
    'provably_fair.how_it_works.p1',
    'provably_fair.how_it_works.p2',
    'provably_fair.how_it_works.p3',
    'provably_fair.how_it_works.p4',
  ]

  return (
    <>
      <Box>
        {textList.map((p, index) => (
          <Typography variant="body" paragraph key={index}>
            {t(p)}
          </Typography>
        ))}
      </Box>
      <HowItWorksCards />
    </>
  )
}

const HowToVerify = () => {
  const { t } = useTranslation()

  const textList = [
    'provably_fair.how_to_verify.p1',
    'provably_fair.how_to_verify.p2',
    'provably_fair.how_to_verify.p3',
    'provably_fair.how_to_verify.p4',
    'provably_fair.how_to_verify.p5',
  ]

  return (
    <Stack sx={{ flexDirection: ['column', null, 'row'] }}>
      <Box sx={{ flex: 0.7 }}>
        {textList.map((p, index) => (
          <Typography variant="body" paragraph key={index}>
            {t(p)}
          </Typography>
        ))}
      </Box>
      <Box sx={{ flex: 0.3, textAlign: 'center' }}>
        <img src={`${path}fairness.jpg`} alt="" />
      </Box>
    </Stack>
  )
}

export default ProvablyFair
