import { gameApi } from '../../apis/strapiApi'
import { colors } from '../../colors'

import { Game } from '../../types'
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Drawer,
  useTheme,
} from '@mui/material'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import './Play.module.scss'
import { launchApi } from '../../apis/launchApi'
import { VerifyAgeModal } from '../../components'
// import { LiveTournamentStatus } from '../../sections/Tournament/LiveTournamentStatus'

const Play = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [url, setUrl] = useState('')
  const [showIFrame, setShowIFrame] = useState(false)
  const [open, setOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams({})
  const { locale, slug, gameId: gameIdParam } = useParams()
  const [game, setGame] = useState<Game>()
  const gameId = game?.attributes?.gameId || gameIdParam
  const isExpo = searchParams.get('isExpo')
  const screen = searchParams.get('screen')
  const [expoUrl, setExpoUrl] = useState('')
  // let expoUrl
  // if (isExpo && url)
  //   expoUrl = new URL(url).searchParams.append('isExpo', isExpo)

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  if (game) {
    console.log('game::', game)
    const extraData = game.attributes?.extraData
  }

  //TODO: Use SWR to fetch data
  useEffect(() => {
    verifyAge()

    if (!gameId) {
      if (slug) {
        const getGame = async () => {
          const data = await gameApi(slug, locale)
          setGame(data?.data[0])
        }
        getGame()
      }
    }
  }, [])

  useEffect(() => {
    launch()
  }, [game?.attributes?.slug, locale])

  const handleCancel = () => {
    setOpen(false)
    navigate('/')
  }

  const verifyAge = async () => {
    const isKeyValid =
      dayjs(localStorage.getItem('ageVerificationKey')).diff(
        new Date(),
        'day'
      ) === 0

    !isKeyValid && setOpen(true)
  }

  const launch = async () => {
    if (slug) {
      if (gameId) {
        // NOTE: Because of the game id condition b2c version only supports jdb and crash games with this implemention
        // TODO: If gameId isn't provided in the url, then get it from game object
        const launchUrl = await launchApi(
          gameId,
          slug,
          locale || 'en',
          game?.attributes?.extraData
        )

        launchUrl && setUrl(launchUrl)

        const toastMsg = t(
          launchUrl ? 'toast.game_launch_success' : 'toast.game_launch_failed'
        )

        enqueueSnackbar(toastMsg, { variant: launchUrl ? 'success' : 'error' })
      }
    }
  }

  useEffect(() => {
    if (isExpo && url) {
      setExpoUrl(`${url}&isExpo=${isExpo}`)

      console.log('slug::', slug)
      if (slug === 'crashroyale') {
        setExpoUrl(`${url}&isExpo=${isExpo}`.replace('v1.8', 'v1.8-expo'))
      }
    }
  }, [isExpo, url, slug, screen])

  return (
    <>
      <Helmet>
        <style type="text/css">{`
        #FooterBox {
          display: none
        }
        @media screen and (min-width: ${theme.breakpoints.values.lg}px) and (min-height: 630px) {    
          body {
              overflow: hidden;
            }
          body {
            background: ${colors.gray[2]} !important
          }

        }
     `}</style>
        <title>{game?.attributes?.name || 'Game'} - iMoon B2B</title>
      </Helmet>
      {url && (
        <iframe
          src={isExpo ? expoUrl : url}
          style={{
            visibility: showIFrame ? 'initial' : 'hidden',
            // height: slug === 'jogodobicho' ? '100vh' : 660,
          }}
          title="game"
          id="gameFrame"
          onLoad={() => setShowIFrame(true)}
        ></iframe>
      )}
      <VerifyAgeModal
        open={open}
        handleClose={() => setOpen(false)}
        handleCancel={handleCancel}
      />
      <Backdrop open={!showIFrame}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <TournamentDrawer open={drawerOpen} setOpen={setDrawerOpen} /> */}
    </>
  )
}

interface StateProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const TournamentDrawer = (props: StateProps) => {
  return (
    <Drawer
      open={props.open}
      anchor="bottom"
      PaperProps={{ sx: { background: colors.gray[2] } }}
    >
      <Container
        sx={{
          pt: 1,
          pb: 6,
          px: { md: 1.5, lg: 3 },
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            maxWidth: { lg: 1200 },
            m: 'auto',
          }}
        >
          {/* <LiveTournamentStatus /> */}
        </Box>
      </Container>
    </Drawer>
  )
}

export default Play
