import {
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Banner } from '../components'
import { bannerOneApi } from '../apis/strapiApi'
import { BannerOneType } from '../types'
import { colors } from '../colors'

// TODO: Move sx styles outside
const BannerOne = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [bannerOne, setBannerOne] = useState<BannerOneType>()
  const { locale } = useParams()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  //TODO: Use SWR to fetch data
  useEffect(() => {
    if (locale) {
      const getBanner = async () => {
        const data = await bannerOneApi(locale)
        setBannerOne(data?.data)
      }
      getBanner()
    }
  }, [locale])

  if (!bannerOne)
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          height: ['143.55vw', 1144, 278, 243, 329],
        }}
      ></Skeleton>
    )

  const {
    attributes: { img, button, headline, subHeadline },
  } = bannerOne

  const imgUrls = {
    desktop: img?.bannerImg?.data.attributes.url,
    mobile: img?.bannerImgMobile?.data.attributes.url,
  }

  return (
    <Banner
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: ['column', 'row'],
        background: {
          xs: 'linear-gradient(340.72deg, #1F1933 61.44%, rgba(42, 36, 58, 0) 80.57%)',
          md: 'linear-gradient(333deg, #1F1933 61.32%, rgba(42, 36, 58, 0) 88.89%)',
          lg: 'linear-gradient(340.72deg, #1F1933 61.44%, rgba(42, 36, 58, 0) 80.57%)',
        },
        '.b2c-banner__grid': {
          overflow: 'hidden',
          borderRadius: 4,
          img: { maxWidth: 415, width: 1 },
        },
        p: 0,
        overflow: 'hidden',
        gap: 4,
        // height: [700, 'auto'],
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={md ? imgUrls.desktop : imgUrls.mobile} />
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'left',
          textAlign: 'center',
          flex: 1,
          position: 'absolute',
          width: 1,
          height: 1,
          px: { xs: 1.5, sm: 2, md: 0 },
          paddingInlineStart: { md: 5, lg: 8 },
        }}
      >
        <Stack
          sx={{
            maxWidth: { md: '50%' },
            '& > *': {
              my: 1,
            },
            mb: { xs: 12, md: 'initial' },
            mt: { md: 6 },
          }}
        >
          <Stack>
            <Typography variant="title3" sx={{ fontSize: { md: 22, lg: 34 } }}>
              {headline}
            </Typography>
            <Typography
              variant="subHeadline"
              sx={{ color: colors.gray[8], fontSize: { md: 16, lg: 18 } }}
            >
              {subHeadline}
            </Typography>
          </Stack>
          {button?.url && (
            <Box>
              <Button variant="contained" href={button?.url} target="_blank">
                {button?.text}
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
    </Banner>
  )
}

export default BannerOne
