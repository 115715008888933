import { colors } from '../../colors'
import {
  Container,
  ContainerProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface PageContentProps extends PropsWithChildren {
  title?: string
  subtitle?: string
}

const PageContent = (props: PageContentProps & ContainerProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  const { t, i18n } = useTranslation()

  const { locale } = useParams()

  useEffect(() => {
    if (locale !== i18n.resolvedLanguage) i18n.changeLanguage(locale)
  }, [locale])

  return (
    <Container
      fixed
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > *:not(:first-child):not(:last-child)': {
          my: [1.5, 2, 3],
        },
        py: 6,
        minHeight: '100vh',
        ...props.sx,
      }}
    >
      <Stack>
        {props.title && (
          <Typography
            variant={md ? 'title2' : 'title3'}
            sx={{ color: colors.gray[12], textAlign: 'center' }}
          >
            {t(props.title)}
          </Typography>
        )}
        {props.subtitle && (
          <Typography variant="bodyMedium" sx={{ textAlign: 'center' }}>
            {t(props.subtitle)}
          </Typography>
        )}
      </Stack>
      {props.children}
    </Container>
  )
}

export default PageContent
