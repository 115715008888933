import {
  faChevronLeft,
  faChevronRight,
  faNewspaper,
} from '@fortawesome/pro-duotone-svg-icons'
import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { colors } from '../../colors'

// import Swiper core and required modules

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'swiper/css'
import 'swiper/css/navigation'
// import function to register Swiper custom elements
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { register } from 'swiper/element/bundle'
import { articleListApi } from '../../apis/strapiApi'
import { Article } from '../../types'
import { Section } from '../../components'
import { ArticleCard } from '../../components/ArticleCard/ArticleCard'
// register Swiper custom elements
register()

const NewsSection = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  const [swiper, setSwiper] = useState(useSwiper())
  const { i18n } = useTranslation()
  const { article } = useParams()

  const [articleList, setArticleList] = useState<Article[]>()

  // TODO: Use SWR to fetch articles
  useEffect(() => {
    const getArticles = async () => {
      const data = await articleListApi(i18n.resolvedLanguage || 'en')
      setArticleList(data?.data)
    }
    getArticles()
  }, [i18n.resolvedLanguage])

  if (!articleList) return <></>

  // TODO: Move sx styles outside
  return (
    <Section
      icon={faNewspaper}
      title="section.news"
      subtitle="section.news_subtitle"
      viewAllUrl={`/${i18n.resolvedLanguage}/news`}
      sx={{
        '.swiper-wrapper': {
          position: 'relative',
          maxHeight: { lg: '446px' },
          '.MuiCard-root': {
            maxWidth: 365,
          },

          '.swiper-slide': {
            height: '100%',
          },
        },

        '.swiper, .swiper-container': {
          margin: md ? 0 : '0 -24px !important',
        },

        '.swiperNav': {
          display: ['none', null, null, 'flex'],
          position: 'absolute',
          top: 275,

          '.MuiIconButton-root': {
            width: 32,
            height: 36,
            borderRadius: '6px',
            bgcolor: colors.gray[5],
            svg: {
              color: colors.gray[12],
            },
          },

          '&--prev': { left: { lg: -40, xl: -52 } },
          '&--next': { right: { lg: -40, xl: -52 } },
        },
      }}
    >
      <Stack>
        <Stack className="swiperNav swiperNav--next">
          <IconButton onClick={() => swiper.slideNext()}>
            <FontAwesomeIcon icon={faChevronRight} fontSize={16} />
          </IconButton>
        </Stack>
        <Stack className="swiperNav swiperNav--prev">
          <IconButton onClick={() => swiper.slidePrev()}>
            <FontAwesomeIcon fixedWidth icon={faChevronLeft} fontSize={16} />
          </IconButton>
        </Stack>
      </Stack>
      <Swiper
        onSwiper={(swiper) => {
          setSwiper(swiper)
        }}
        grabCursor
        breakpoints={{
          0: {
            slidesPerView: 1.4,
            spaceBetween: 6,
            navigation: false,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,
          },
          [theme.breakpoints.values.xs]: {
            slidesPerView: 2,
            spaceBetween: 6,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,
          },
          [theme.breakpoints.values.sm]: {
            slidesPerView: 2,
            spaceBetween: 6,
            navigation: true,
          },
          [theme.breakpoints.values.md]: {
            slidesPerView: 3,
            spaceBetween: 6,
            navigation: true,
          },
          [theme.breakpoints.values.lg]: {
            slidesPerView: 3,
          },
        }}
        on={{
          afterInit: (e) => {
            e.slideTo(0)
          },
        }}
      >
        {articleList.map(
          (item) =>
            item.attributes?.slug !== article && (
              <SwiperSlide key={item.id}>
                <ArticleCard
                  {...item}
                  sx={{
                    '.news__card--img': { height: ['27vw', 195, 153, 200] },
                  }}
                  size={md ? 'medium' : 'small'}
                />
              </SwiperSlide>
            )
        )}
      </Swiper>
    </Section>
  )
}

export default NewsSection
