import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-duotone-svg-icons'
import { Box, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { colors } from '../../colors'

// import Swiper core and required modules

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'swiper/css'
import 'swiper/css/navigation'
// import function to register Swiper custom elements
import { useState } from 'react'
import { register } from 'swiper/element/bundle'
import { Article } from '../../types'
import { ArticleCard } from '../ArticleCard/ArticleCard'
// register Swiper custom elements
register()

interface NewsFeaturedSliderProps {
  articleList: Article[]
}

const NewsFeaturedSlider = (props: NewsFeaturedSliderProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const [swiper, setSwiper] = useState(useSwiper())

  // TODO: Move sx styles outside
  return (
    <Box
      sx={{
        '.swiper-wrapper': {
          position: 'relative',
        },

        '.swiperNav': {
          display: ['none', null, 'flex'],
          position: 'absolute',
          top: [null, 275, 110, 175],

          '.MuiIconButton-root': {
            width: 32,
            height: 36,
            borderRadius: '6px',
            bgcolor: colors.gray[5],
            svg: {
              color: colors.gray[12],
            },
          },

          '&--prev': { left: -52 },
          '&--next': { right: -52 },
        },
      }}
    >
      <Stack sx={{ position: 'relative' }}>
        <Stack className="swiperNav swiperNav--next">
          <IconButton onClick={() => swiper.slideNext()}>
            <FontAwesomeIcon icon={faChevronRight} fontSize={16} />
          </IconButton>
        </Stack>
        <Stack className="swiperNav swiperNav--prev">
          <IconButton onClick={() => swiper.slidePrev()}>
            <FontAwesomeIcon fixedWidth icon={faChevronLeft} fontSize={16} />
          </IconButton>
        </Stack>
      </Stack>
      <Swiper
        spaceBetween={16}
        onSwiper={(swiper) => {
          setSwiper(swiper)
        }}
        grabCursor
        on={{
          afterInit: (e) => {
            e.slideTo(0)
          },
        }}
        loop
        autoplay={{ delay: 5000 }}
      >
        {props.articleList.map((item) => (
          <SwiperSlide key={item.id}>
            <ArticleCard
              {...item}
              size={'medium'}
              orientation={md ? 'horizontal' : 'vertical'}
              sx={{
                '.news__card--img': {
                  width: [1, 1, 741.9, 830],
                  height: ['calc(59.8vw - 48px)', 395, 262.41, 374],
                },
                '.news__card--content': {
                  display: 'flex',
                  flexDirection: 'column',
                  width: ['auto', 'auto', 532, 532],
                },
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default NewsFeaturedSlider
