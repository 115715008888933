import { Box, useMediaQuery, useTheme } from '@mui/material'
import { Hero, PageContent } from '../../components'
import {
  AllProducts,
  ContactUs,
  CrashCollection,
  FactGrid,
  LevelUpBanner,
  NewsSection,
} from '../../sections'
import BannerOne from '../../sections/BannerOne'
import BannerProducts from '../../sections/LevelUpBanner/BannerProducts'
import VerifiedPartners from '../../sections/VerifiedPartners/VerifiedPartners'

const Home = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box>
      <Hero />
      <PageContent>
        <CrashCollection />
        <FactGrid />
        {/* <LevelUpBanner /> */}
        <BannerProducts />
        <AllProducts />
        {/* <B2CBanner /> */}
        <BannerOne />
        <VerifiedPartners />
        <NewsSection />
        <ContactUs />
      </PageContent>
    </Box>
  )
}

export default Home
