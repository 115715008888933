import {
  faFileCertificate,
  faShieldCheck,
  faTrophyStar,
} from '@fortawesome/pro-duotone-svg-icons'
import { FactItemProps } from './FactGrid'

export const factList: FactItemProps[] = [
  {
    icon: faFileCertificate,
    description: 'home.fact_grid.fact_one_desc',
    title: 'home.fact_grid.fact_one_title',
    href: 'licenses',
  },
  {
    icon: faShieldCheck,
    description: 'home.fact_grid.fact_two_desc',
    title: 'home.fact_grid.fact_two_title',
    href: 'provably-fair',
  },
  {
    icon: faTrophyStar,
    description: 'home.fact_grid.fact_three_desc',
    title: 'home.fact_grid.fact_three_title',
    href: 'tournaments',
  },
]
