import { faCookie } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Card,
  CardContent,
  Fade,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from '../../colors'

const CookieConsent = () => {
  const { t, i18n } = useTranslation()
  const [show, setShow] = useState(false)

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent')
    if (!cookieConsent) setShow(true)
  }, [])

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accept')
    setShow(false)
  }
  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'reject')
    setShow(false)
  }

  return (
    <Fade in={show}>
      <Card
        sx={{
          maxWidth: 500,
          position: 'fixed',
          bottom: 0,
          right: 0,
          m: 1,
          borderRadius: 4,
          background: 'linear-gradient(180deg, #1D1929 0%, #2D2740 100%)',
          zIndex: (theme) => theme.zIndex.modal,
        }}
      >
        <CardContent>
          <Stack sx={{ gap: 1 }}>
            <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <FontAwesomeIcon icon={faCookie} />
              <Typography
                variant="headline"
                sx={{ color: colors.gray[9], fontWeight: 800 }}
              >
                {t('cookies.title')}
              </Typography>
            </Stack>
            {/* Add text, use cookies for authentication and authorization */}
            <Typography variant="body" sx={{ lineHeight: 1.5 }}>
              {t('cookies.p1')}
            </Typography>
            <Stack
              sx={{
                flexDirection: ' row',
                justifyContent: 'flex-end',
                gap: 1,
                mt: 0.5,
              }}
            >
              <Button size="small" variant="contained" onClick={handleAccept}>
                {t('general.accept')}
              </Button>
              <Button size="small" onClick={handleReject}>
                {t('general.reject')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Fade>
  )
}

export default CookieConsent
