import { Stack } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Footer, Header } from '..'
import CookieConsent from '../CookieConsent/CookieConsent'

const Layout = (props: PropsWithChildren) => {
  return (
    <Stack sx={{ minHeight: 1 }}>
      <Header />
      <Stack sx={{ flex: 1, overflowY: 'scroll' }}>{props.children}</Stack>
      <Footer />
      <CookieConsent />
    </Stack>
  )
}

export default Layout
