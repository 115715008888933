import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Column } from '../../../components'
import { CrashExtraPanel } from '../../../types'

interface ExtraPanelList {
  list: CrashExtraPanel[]
}

// const extraPanelList: ExtraPanelList[] = [
//   {
//     icon: '7_icon.jpg',
//     desc: info.extra_panel.playing_desc,
//     img: '7.jpg',
//     title: info.extra_panel.playing_title,
//   },
//   {
//     icon: '8_icon.jpg',
//     desc: info.extra_panel.chat_desc,
//     img: '8.jpg',
//     title: info.extra_panel.chat_title,
//   },
//   {
//     icon: '9_icon.jpg',
//     desc: info.extra_panel.fairness_desc,
//     img: '9.jpg',
//     title: info.extra_panel.fairness_title,
//   },
// ]

export const ExtraPanel = (props: ExtraPanelList) => {
  useEffect(() => {
    props.list.map((item) => {
      const textDom = document.getElementById(`extrapanel--${item.id}__text`)
      if (textDom) textDom.innerHTML = item.text
    })
  }, [props])

  const row = (args: CrashExtraPanel, reverse: boolean) => (
    <Stack
      sx={{
        flexDirection: ['column-reverse', reverse ? 'row-reverse' : 'row'],
        alignItems: 'center',
        gap: 1.5,

        '.img-container': {
          position: 'relative',
          img: { position: 'relative', width: 1, zIndex: 1, borderRadius: 3 },
        },

        '.img-container--icon': {
          height: [56, 81, 100, 100, 100],
          img: { width: [70, 80, 100] },
        },
      }}
      key={args.id}
    >
      <Column>
        <Box className="img-container">
          <img src={args.img.data?.attributes.url || ''} alt={args.title} />
        </Box>
      </Column>
      <Column wide>
        <Stack sx={{ gap: 1.5 }}>
          <Stack
            sx={{
              flexDirection: ['row'],
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1.5,
              mt: { md: 'auto' },
            }}
          >
            <Box
              className="img-container img-container--icon"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <img src={args.icon.data.attributes.url || ''} alt="" />
            </Box>
            <Typography variant="subHeadline" textAlign="center">
              {args.title}
            </Typography>
          </Stack>
          <Typography
            variant="body"
            paragraph
            id={`extrapanel--${args.id}__text`}
          ></Typography>
        </Stack>
      </Column>
    </Stack>
  )

  return (
    <Box>{props.list.map((item, index) => row(item, index % 2 !== 0))}</Box>
  )
}
