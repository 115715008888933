import { useEffect } from 'react'
import { CrashColumn } from '../../../types'
import { Box, Stack, Typography } from '@mui/material'
import { Column } from '../../../components'

export const AboutGame = (props: CrashColumn) => {
  useEffect(() => {
    props.details.map((item) => {
      const textDom = document.getElementById(`aboutgame--${item.id}__text`)
      if (textDom) textDom.innerHTML = item.text
    })
  }, [props])

  return (
    <Box>
      {props.details.map((item) => (
        <Stack
          sx={{ flexDirection: ['column', 'row'], alignItems: 'center' }}
          key={item.id}
        >
          {item.img?.data?.attributes.url ? (
            <>
              <Column wide>
                <Typography
                  paragraph
                  variant="body"
                  id={`aboutgame--${item.id}__text`}
                ></Typography>
              </Column>
              <Column>
                <Box
                  sx={{
                    position: 'relative',
                    img: {
                      position: 'relative',
                      width: 1,
                      zIndex: 1,
                    },
                    height: ['calc(65vw - 62px)', 190, 256],
                    mb: 1,
                  }}
                >
                  <img src={item.img.data.attributes.url} alt="crash points" />
                </Box>
              </Column>
            </>
          ) : (
            <Typography
              paragraph
              variant="body"
              id={`aboutgame--${item.id}__text`}
            ></Typography>
          )}
        </Stack>
      ))}
    </Box>
  )
}
