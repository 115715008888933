import badgeNew from '../../assets/badge_new.png'
import badgeHot from '../../assets/badge_hot.png'
import badgeSoon from '../../assets/badge_soon.png'
import {
  Box,
  Button,
  Card,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { PlayDemoButton } from '..'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from '../../colors'
import { Game } from '../../types'

// Add title, rtp and optional demo

export interface GameCardProps {
  title: string
  rtp?: number
  releaseDate?: Date
  gameId?: string
  alt?: string
  slug: string
  gameplay?: string
  tags?: {
    new?: boolean
    comingSoon?: boolean
    hot?: boolean
  }
}

// TODO: Add transition on hover
const sx: SxProps<Theme> = {
  position: 'relative',
  '.MuiCard-root': {
    display: 'flex',
    borderRadius: ['6px', '8px', '12px'],
    overflow: 'hidden',
    background: 'none',
    // maxWidth: [104, 174],
    width: '100%',
    height: ['calc(55vw - 15.5px)', 205, 176, 240],
    img: {
      maxWidth: 1,
      zIndex: 1,
      overflow: 'hidden',
      borderRadius: ['6px', '8px', '12px'],
    },
  },

  '.MuiSkeleton-root': {
    height: 1,
    position: 'absolute',
    inset: 0,
    borderRadius: ['6px', '8px', '12px'],
  },

  ':hover': {
    '.game-card__hover': { opacity: 1 },
  },

  '.game-card__badge': {
    position: 'absolute',
    width: 31,
    left: 12,
    top: -5,
    zIndex: 2,
  },

  '.game-card__hover': {
    background: '#1D1929EB',
    position: 'absolute',
    top: 0,
    opacity: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '.MuiButton-root': {
      borderRadius: '6px',
    },
    transition: 'opacity 100ms ease-in-out',
  },
}

export const GameCard = (props: Game) => {
  const { attributes } = props
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const [loading, setLoading] = useState(true)
  // const path = `${cdnUrl}games/${props.slug}`

  const navigate = useNavigate()

  if (!attributes)
    return (
      <Box sx={sx}>
        <Card>
          <Skeleton variant="rectangular" />
        </Card>
      </Box>
    )

  const hasDetails = attributes.detailsPage?.length > 0

  const handleMobileClick = () => {
    !lg &&
      hasDetails &&
      navigate(`/${i18n.resolvedLanguage}/products/${attributes.slug}`)
  }

  const buttonClick = () => {
    navigate(`/${i18n.resolvedLanguage}/products/${attributes.slug}`)
  }

  return (
    <Box
      sx={{
        '&:hover': lg ? { '.game-card__badge': { display: 'none' } } : {},
        ...sx,
      }}
    >
      {attributes.tags && (
        <img
          className="game-card__badge"
          src={
            attributes.tags === 'new'
              ? badgeNew
              : attributes.tags === 'hot'
              ? badgeHot
              : badgeSoon
          }
          alt="badge"
        />
      )}
      {attributes.coverImg.data ? (
        <>
          <Card elevation={4} onClick={handleMobileClick}>
            <Skeleton
              variant="rectangular"
              sx={{
                display: loading ? 'block' : 'none',
              }}
            />
            {attributes.coverImg.data && (
              <img
                src={attributes.coverImg.data?.attributes.url}
                alt={attributes.coverImg.data?.attributes.alternativeText || ''}
                onLoad={() => setLoading(false)}
              />
            )}
          </Card>
          {lg && (
            <Card className="game-card__hover" onClick={handleMobileClick}>
              <Stack gap="5px" sx={{ alignItems: 'center' }}>
                <Stack>
                  <Typography variant="buttonLarge" component="h4">
                    {attributes.name}
                  </Typography>
                  {attributes.rtp && (
                    <Typography variant="buttonSmall" color="primary">
                      RTP:{attributes.rtp}%
                    </Typography>
                  )}
                </Stack>
                <Typography
                  variant="caption"
                  sx={{ color: `${colors.gray[12]} !important` }}
                >
                  {t(
                    attributes.gameId && attributes.tags !== 'comingSoon'
                      ? 'general.available'
                      : 'general.coming_soon'
                  )}
                </Typography>
                {hasDetails && (
                  <Link
                    to={`/${i18n.resolvedLanguage}/products/${attributes.slug}`}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      fullWidth
                      sx={{
                        p: 1,
                        px: 1.5,
                        height: '32px !important',
                        lineHeight: 1,
                        fontSize: 12,
                        mb: '4px',
                      }}
                      onClick={buttonClick}
                    >
                      {t('general.read_more')}
                    </Button>
                  </Link>
                )}
                <Stack>
                  {attributes.gameId && attributes.tags !== 'comingSoon' && (
                    <PlayDemoButton
                      slug={attributes.slug}
                      gameId={attributes.gameId}
                      sx={{
                        p: 1,
                        px: 1.5,
                        height: '32px !important',
                        lineHeight: 1,
                        fontSize: 12,
                      }}
                      size="small"
                      variant="contained"
                      color="secondary"
                      fullWidth
                    />
                  )}
                  {attributes.gameId && attributes.tags !== 'comingSoon' && (
                    <Typography
                      variant="caption"
                      sx={{
                        width: 'auto',
                        color: `${colors.gray[7]} !important`,
                        position: 'absolute',
                        bottom: { md: 0, lg: 10 },
                        textAlign: 'center',
                      }}
                    >
                      Game ID: {attributes.gameId}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Card>
          )}
        </>
      ) : (
        <Card
          sx={{
            background: `${colors.gray[3]} !important`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            border: `1px solid ${colors.gray[11]}`,
            '.MuiButton-root': {
              borderRadius: '6px',
            },
          }}
          elevation={6}
          onClick={handleMobileClick}
        >
          <Stack gap={0.5}>
            <Stack>
              <Typography variant="buttonLarge" component="h4">
                {attributes.name}
              </Typography>
              {attributes.rtp && (
                <Typography variant="buttonMedium" color="primary">
                  RTP:{attributes.rtp}%
                </Typography>
              )}
            </Stack>
            <Typography
              variant="caption"
              sx={{ color: `${colors.gray[12]} !important` }}
            >
              {t(
                attributes.gameId ? 'general.available' : 'general.coming_soon'
              )}
            </Typography>
            {hasDetails && (
              <Link
                to={`/${i18n.resolvedLanguage}/products/${attributes.slug}`}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  onClick={buttonClick}
                >
                  {t('general.read_more')}
                </Button>
              </Link>
            )}
            {attributes.gameId && (
              <PlayDemoButton
                slug={attributes.slug}
                gameId={attributes.gameId}
                sx={{ flex: 0.3 }}
                variant="outlined"
                color="secondary"
                fullWidth
              />
            )}
            {attributes.gameId && (
              <Typography
                variant="caption"
                sx={{
                  width: 'auto',
                  color: `${colors.gray[7]} !important`,
                  position: 'absolute',
                  bottom: { md: 0, lg: 10 },
                  textAlign: 'center',
                }}
              >
                Game ID: {attributes.gameId}
              </Typography>
            )}
          </Stack>
        </Card>
      )}
    </Box>
  )
}
