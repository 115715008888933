import { faGamepadModern } from '@fortawesome/pro-duotone-svg-icons'
import Section from '../../components/Section/Section'

// Import Swiper React components
import { SwiperSlide } from 'swiper/react'

import { gameListApi } from '../../apis/strapiApi'

import { Game } from '../../types'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GameCard } from '../../components/GameCard/GameCard'
import { CollectionSlider } from '../../components'
import i18n from '../../i18n'

const RelatedProducts = () => {
  const [gameList, setGameList] = useState<Game[]>()
  const { locale, product } = useParams()

  useEffect(() => {
    const getList = async () => {
      const data = await gameListApi(i18n.resolvedLanguage || 'en')
      setGameList(data?.data)
    }
    getList()
  }, [locale])

  if (!gameList) return <></>
  return (
    <Section
      title="section.related_products"
      icon={faGamepadModern}
      sx={{ mt: 3 }}
    >
      <CollectionSlider>
        {gameList.map(
          (gameCard) =>
            gameCard.attributes?.slug !== product && (
              <SwiperSlide key={gameCard.id}>
                <GameCard {...gameCard} />
              </SwiperSlide>
            )
        )}
      </CollectionSlider>
    </Section>
  )
}

export default RelatedProducts
