import { faRocketLaunch } from '@fortawesome/pro-duotone-svg-icons'
import Section from '../../components/Section/Section'

// Import Swiper React components
import { SwiperSlide } from 'swiper/react'

import { productListApi } from '../../apis/strapiApi'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GameCard } from '../../components/GameCard/GameCard'
import { GameList } from '../NewProductsCollection/NewProductsCollection'
import { useParams } from 'react-router-dom'
import { CollectionSlider } from '../../components'
import GameCardSkeleton from '../../components/GameCard/GameCardSkeleton'
import { gcSkeletonList } from '../../constants'

const AllProducts = () => {
  const { locale } = useParams()
  const [productList, setProductList] = useState<GameList>()

  useEffect(() => {
    const getList = async () => {
      const data = await productListApi(locale)
      setProductList(data?.data)
    }
    getList()
  }, [locale])

  return (
    <Section
      title="section.all_products"
      icon={faRocketLaunch}
      viewAllUrl={`/${locale}/products`}
      sx={{ '.swiperNav-container': { top: 0, right: '96px' } }}
    >
      <CollectionSlider>
        {productList
          ? productList.attributes?.games.data.map((gameCard) => (
              <SwiperSlide key={gameCard.id}>
                <GameCard {...gameCard} />
              </SwiperSlide>
            ))
          : gcSkeletonList.map((gameCard, index) => (
              <SwiperSlide key={index}>
                <GameCardSkeleton />
              </SwiperSlide>
            ))}
      </CollectionSlider>
    </Section>
  )
}

export default AllProducts
