import { PropsWithChildren } from 'react'
import PageContent from '../PageContent/PageContent'
import { ContactUs } from '../../sections'
import RelatedProducts from '../../sections/RelatedProducts/RelatedProducts'

const ProductDetails = (props: PropsWithChildren) => {
  return (
    <PageContent>
      {props.children}
      <RelatedProducts />
      <ContactUs />
    </PageContent>
  )
}

export default ProductDetails
