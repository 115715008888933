import { Box, Skeleton, Stack } from '@mui/material'
import { CrashGameMode } from '../../../types'

export const Mode = (props: CrashGameMode) => {
  return (
    <Stack
      sx={{
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 3,
        img: { borderRadius: 3, width: [1, 'auto', '30%'] },

        '.MuiBox-root': {
          width: 1,
          position: 'relative',
          img: { position: 'relative', width: 1, zIndex: 1 },
          height: ['calc(134vw - 108px)', 300, 359, 494],
        },
      }}
    >
      {props.images.data.map((item) => (
        <Box key={item.id}>
          <img src={`${item.attributes.url}?5`} alt="" />
        </Box>
      ))}
    </Stack>
  )
}
