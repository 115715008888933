import { Box, Stack, SxProps, Theme, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { bannerProductsApi } from '../../apis/strapiApi'
import Banner from '../../components/Banner/Banner'
import { GameCard } from '../../components/GameCard/GameCard'
import GameCardSkeleton from '../../components/GameCard/GameCardSkeleton'
import { cdnUrl, gcSkeletonList } from '../../constants'
import { theme } from '../../theme'
import { BannerProductsType } from '../../types'
import './styles.scss'
import { Interweave } from 'interweave'
import { colors } from '../../colors'

const sx: SxProps<Theme> = {
  display: 'flex',
  border: 'none',

  '.levelup': {
    '&__title': {
      textAlign: 'center',
      img: {
        marginTop: '-54px',
      },
    },
  },
}

const BannerProducts = () => {
  const { t, i18n } = useTranslation()
  const [bannerProducts, setBannerProducts] = useState<BannerProductsType>()

  useEffect(() => {
    const getData = async () => {
      const data = await bannerProductsApi(i18n.resolvedLanguage || 'en')
      setBannerProducts(data?.data)
    }
    getData()
  }, [i18n.resolvedLanguage])

  if (!bannerProducts) return <></>

  const {
    attributes: { img, content, products },
  } = bannerProducts

  return (
    <Banner sx={sx}>
      <Box
        className="levelup__container"
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          gap: 1.5,
          justifyContent: 'space-between',
          alignItems: { xs: 'center', md: 'end' },
        }}
      >
        <Stack className="levelup__title">
          <Box sx={{ img: { borderRadius: 3 } }}>
            {img.data.attributes.url && (
              <img src={img.data.attributes.url} alt="planet" />
            )}
          </Box>
          <Box
            sx={{
              color: `${colors.gray[12]} !important`,
              span: { color: theme.palette.primary.main },
              'h3,h4': { p: 0, m: 0 },
              h4: { fontSize: 28, fontWeight: 800 },
              h3: { fontSize: 34, fontWeight: 800 },
            }}
          >
            <Interweave content={content} tagName="div" />
          </Box>
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {products
            ? products.data.map((gameCard, index) => (
                <Box
                  sx={{
                    px: 1,
                    display: { xs: index >= 2 ? 'none' : 'block', md: 'block' },
                    '.MuiPaper-root.MuiCard-root': {
                      width: [1, 174, 125, 174],
                    },
                    height: 'auto !important',
                    img: {
                      height: 'auto !important',
                    },
                  }}
                  key={gameCard.id}
                >
                  <GameCard {...gameCard} />
                </Box>
              ))
            : gcSkeletonList.map((gameCard, index) => (
                <Box
                  sx={{
                    display: { xs: index >= 2 ? 'none' : 'block', md: 'block' },
                  }}
                  key={index}
                >
                  <GameCardSkeleton />
                </Box>
              ))}
        </Stack>
      </Box>
    </Banner>
  )
}

export default BannerProducts
