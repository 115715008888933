import { colors } from '../../colors';
import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';

export const factItemSx: SxProps<Theme> = {
  alignItems: ['flex-start', 'center'],
  gap: 0.75,
  maxWidth: ['100%', 270],
  '.factItem': {
    '&__title': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
      '&--container': {
        flexDirection: ['row', 'column'],
        alignItems: 'center',
        width: '100%',
        gap: 2,
      },
    },

    '&__icon': {
      position: 'relative',
      justifyContent: 'center',
      width: 64,
      height: 64,
      border: `1px solid ${colors.gray[3]}`,
      borderRadius: 4,
      background:
        'linear-gradient(295.7deg, #2A243A 49.91%, rgba(42, 36, 58, 0) 97.93%)',
      transition: 'rotate 150ms ease-in-out',

      svg: {
        transition: 'opacity 150ms ease-in-out',
      },

      img: {
        position: 'absolute',
        transform: 'translate(16px, 1px)',
        opacity: 0,
        rotate: '20deg',
        transition: 'opacity 150ms ease-in-out',
      },

      '&:hover': {
        rotate: '-20deg',
        svg: { opacity: 0 },
        img: {
          opacity: 1,
        },
      },
    },

    '&__description': {
      alignItems: 'center',
      gap: 0.75,
      flex: 1,
    },

    '&__button': {
      mt: 1,
      borderRadius: 16,
      display: ['none', 'flex'],
    },
  },

  '.MuiIconButton-root': {
    display: ['flex', 'none'],
  },
};
