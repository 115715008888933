import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface SuccessResponse {
  success: boolean;
  status: string;
  uploadUrl: string;
  publicReadAccessUrl: string;
}

export const getUploadUrlApi = async (
  fileCategory: string,
  fileExtension: string,
  fileMimeType: string
) => {
  const data = JSON.stringify({
    fileCategory,
    fileExtension,
    fileMimeType,
  });

  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_ALIEN_OPERATOR_BASE_API_PATH}user/get-upload-url`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };

  try {
    const res: AxiosResponse = await axios(config);
    const data = res.data as SuccessResponse;

    console.log('public access read url::', data.publicReadAccessUrl);

    return data.uploadUrl;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const uploadApi = async (
  uploadUrl: string,
  file: File,
  fileMimeType: string
) => {
  const data = file;

  console.log('data::', data);

  const config: AxiosRequestConfig = {
    method: 'put',
    maxBodyLength: Infinity,
    url: uploadUrl,
    headers: {
      'Content-Type': fileMimeType,
      'x-ms-blob-type': 'BlockBlob',
    },
    data,
  };

  try {
    const res: AxiosResponse = await axios(config);
    return true;
  } catch (error) {
    console.error(error);
    return;
  }
};

// https://alienapi.imoon.com/operator/test/
