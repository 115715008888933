import { colors } from '../../colors'
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons'
import { faArrowUpRight } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import { factItemSx } from './factItemStyles'
import { factList } from './factList'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { cdnUrl } from '../../constants'

const path = `${cdnUrl}company/`

const FactGrid = () => {
  return (
    <Stack
      sx={{
        flexDirection: ['column', 'row'],
        flexWrap: ['wrap', null, null, 'nowrap'],
        rowGap: 4.5,
        justifyContent: 'space-around',
        paddingBottom: '54px',
      }}
    >
      {factList.map((item) => (
        <FactItem {...item} key={item.title} />
      ))}
    </Stack>
  )
}

export interface FactItemProps {
  icon: IconDefinition
  title: string
  description: string
  url?: string
  href: string
}

const FactItem = (props: FactItemProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    navigate(`company/${props.href}`)
  }

  return (
    <Stack sx={factItemSx} className="factItem__container">
      <Stack className="factItem__title--container">
        <Stack className="factItem__icon">
          <FontAwesomeIcon
            icon={props.icon}
            fontSize={32}
            color={colors.gray[8]}
          />
          <img src={`${path}${props.href}/icon.png`} alt="" width={80} />
        </Stack>
        <Stack className="factItem__title">
          <Typography variant="subHeadline">{t(props.title)}</Typography>
          <IconButton onClick={handleClick}>
            <FontAwesomeIcon icon={faArrowUpRight} fontSize={16} />
          </IconButton>
        </Stack>
      </Stack>
      <Stack
        className="factItem__description"
        sx={{ justifyContent: 'space-between' }}
      >
        <Typography variant="bodySmall" textAlign={['start', 'center']}>
          {t(props.description)}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          className="factItem__button"
          onClick={handleClick}
        >
          {t('general.learn_more')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default FactGrid
