import { colors } from '../../../colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Card,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  faBinary,
  faArrowDown,
  faArrowRight,
  faEquals,
  faPlus,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faBinaryCircleCheck,
  faCodeMerge,
  faServer,
  faUserTieHair,
} from '@fortawesome/pro-duotone-svg-icons'
import { cdnUrl } from '../../../constants'

const path = `${cdnUrl}company/provably-fair/`

// TODO: Refactor cards code
export const HowItWorksCards = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Stack
      sx={{
        flexDirection: ['column', null, 'row'],
        alignItems: 'center',
        textAlign: 'center',
        gap: 1,
        '.MuiPaper-root': {
          borderRadius: 3,
        },
        '.MuiCardContent-root': {
          background: colors.gray[3],
          px: 1.5,
        },
        '.MuiTypography-root': {
          color: colors.gray[6],
        },
        '.icon': {
          width: 45,
          height: 45,
          alignItems: 'center',
          justifyContent: 'center',
          background: colors.gray[5],
          borderRadius: '50%',
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          textAlign: 'center',
          gap: 1,
        }}
      >
        <ServerSeedCard />
        <Stack className="icon">
          <FontAwesomeIcon icon={faPlus} fixedWidth />
        </Stack>
        <PlayerSeedCard />
      </Stack>
      <Stack className="icon">
        <FontAwesomeIcon icon={faEquals} fixedWidth />
      </Stack>
      <CombinedSeedCard />

      <Card>
        <CardContent>
          <Stack sx={{ gap: 0.75, alignItems: 'center' }}>
            <Typography variant="bodyBold">Hashed in SHA Seed:</Typography>
            <Typography
              variant="bodyBold"
              sx={{ color: `${colors.gray[12]} !important` }}
            >
              d41d8cd98f00b204e9800998ecf8427e
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      <Stack className="icon">
        <FontAwesomeIcon icon={md ? faArrowRight : faArrowDown} fixedWidth />
      </Stack>
      <Card>
        <CardContent>
          <Stack sx={{ gap: 0.75, alignItems: 'center' }}>
            <Typography variant="bodyBold">Crash Point</Typography>
            <Typography
              variant="bodyBold"
              sx={{ color: `${colors.gray[12]} !important` }}
            >
              25.00
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  )
}

const ServerSeedCard = () => {
  return (
    <Stack
      sx={{
        flexDirection: ['column', null, 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Card>
        <CardContent>
          <Stack sx={{ gap: 0.75, alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={faServer}
              fixedWidth
              fontSize={48}
              color={colors.gray[8]}
            />
            <Typography variant="bodyBold">Server Seed:</Typography>
            <Stack
              sx={{
                background: colors.gray[1],
                width: 65,
                height: 65,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
              }}
            >
              <img
                src={`${path}server.jpg`}
                alt=""
                style={{ borderRadius: '50%' }}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  )
}

const PlayerSeedCard = () => {
  return (
    <Stack
      sx={{
        flexDirection: ['column', null, 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Card>
        <CardContent>
          <Stack sx={{ gap: 0.75, alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={faUserTieHair}
              fixedWidth
              fontSize={48}
              color={colors.gray[8]}
            />
            <Typography variant="bodyBold">Player Seed:</Typography>
            <Stack
              sx={{
                background: colors.gray[1],
                width: 65,
                height: 65,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
              }}
            >
              <img
                src={`${path}player.jpg`}
                alt=""
                style={{ borderRadius: '50%' }}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  )
}

const CombinedSeedCard = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Stack
      sx={{
        flexDirection: ['column', null, 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Card>
        <CardContent>
          <Stack sx={{ gap: 0.75, alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={faCodeMerge}
              fixedWidth
              fontSize={48}
              color={colors.gray[8]}
            />
            <Typography variant="bodyBold">Combined Seed:</Typography>
            <Stack
              sx={{
                background: colors.gray[2],
                width: 65,
                height: 65,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
              }}
            >
              <FontAwesomeIcon
                icon={faBinaryCircleCheck}
                fontSize={32}
                color={colors.gray[8]}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Stack className="icon">
        <FontAwesomeIcon icon={md ? faArrowRight : faArrowDown} fixedWidth />
      </Stack>
    </Stack>
  )
}
