import logo from '../../assets/logo.svg'
import logoMobile from '../../assets/logo_mobile.svg'
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AppBar,
  Box,
  Button,
  Fade,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { colors } from '../../colors'
import { NavItemProps } from '../../types'
import LanguageSelect from '../LanguageSelect/LanguageSelect'
import { MobileMenu } from './MobileMenu'
import { navList } from './navList'

const Header = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  return (
    <>
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          background: colors.gray[3],
          '.MuiToolbar-root': { justifyContent: 'space-between', py: 1, px: 2 },
          '.MuiIconButton-root': {
            borderRadius: 2,
            p: 1.75,
            svg: {
              fontSize: 16,
            },
          },
        }}
      >
        <Toolbar>
          {md ? (
            <>
              <Stack
                onClick={() => navigate(`/${i18n.resolvedLanguage}`)}
                sx={{ cursor: 'pointer' }}
              >
                <img
                  src={logo}
                  alt="imoon"
                  width={97}
                  onClick={() => navigate(`/${i18n.resolvedLanguage}`)}
                />
              </Stack>

              <Nav />
              <Box
                sx={{
                  display: 'flex',
                  '& > *:not(:last-child)': {
                    marginInlineEnd: 1,
                  },
                  alignItems: 'center',
                }}
              >
                <Link to={`/${i18n.resolvedLanguage}/contact-us`}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ p: '10px 20px', whiteSpace: 'pre' }}
                  >
                    {t('nav.contact_us')}
                  </Button>
                </Link>
                <LanguageSelect />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ flex: 1 }}>
                <MobileMenu />
              </Box>
              <Stack
                onClick={() => navigate(`/${i18n.resolvedLanguage}`)}
                sx={{ cursor: 'pointer' }}
              >
                <img src={logoMobile} alt="imoon" height={38} />
              </Stack>
              <Box sx={{ flex: 1, textAlign: 'end' }}>
                <Link to={`/${i18n.resolvedLanguage}/contact-us`}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    sx={{ p: '8.5px 16px', fontSize: 12 }}
                  >
                    {t('nav.contact_us')}
                  </Button>
                </Link>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

const Nav = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        display: ['none', null, 'flex'],
        flexDirection: 'row',
        gap: 1,
        '&, .MuiList-root': {
          '.MuiButton-root': {
            color: 'white',
            fontSize: 18,
            background: 'none',
            '&:hover': { color: theme.palette.primary.main },
          },
        },
      }}
    >
      {navList.map((item) => (
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            '.MuiButton-root': { fontSize: 16 },
            svg: { fontSize: 20 },
            gap: 1,
            a: {
              color: 'inherit',
              textDecoration: 'none',
            },
            '& div > a > button': {
              px: [null, null, 1.5, 2.5],
            },
          }}
          key={item.href}
        >
          {item.children ? (
            <>
              <Button
                id={item.href}
                startIcon={<FontAwesomeIcon icon={item.icon} />}
                endIcon={
                  item.children && <FontAwesomeIcon icon={faChevronDown} />
                }
                onClick={handleClick}
              >
                {t(item.label)}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                sx={{
                  '.MuiList-root': {
                    button: {
                      color: 'white',
                      background: 'none',

                      '&:hover': { color: theme.palette.primary.main },
                    },
                  },
                }}
              >
                {item.children.map((item) => (
                  <MenuItem
                    onClick={handleClose}
                    disableGutters
                    key={item.href}
                    sx={{
                      py: 0,
                      'a, .MuiButton-root': {
                        width: 1,
                        justifyContent: 'left',
                        borderRadius: 0,
                      },
                    }}
                  >
                    <NavItem {...item} />
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <NavItem {...item} />
          )}
        </Stack>
      ))}
    </Box>
  )
}

const NavItem = (props: NavItemProps) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  return (
    <Box
      sx={{
        flex: 1,
        '.active': {
          '.MuiButton-root': { color: theme.palette.primary.main },
        },
      }}
    >
      <NavLink
        to={`${i18n.resolvedLanguage}/${props.href}`}
        className={({ isActive, isPending }) =>
          isPending ? 'pending' : isActive ? 'active' : ''
        }
      >
        <Button
          id={props.href}
          startIcon={<FontAwesomeIcon icon={props.icon} />}
        >
          {t(props.label)}
        </Button>
      </NavLink>
    </Box>
  )
}

export default Header
