import { Box, BoxProps, useMediaQuery, useTheme } from '@mui/material'

interface ColumnProps {
  wide?: boolean
}

export const Column = (props: ColumnProps & BoxProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <Box
      sx={
        md
          ? { flex: props.wide ? 0.65 : 0.35, ...props.sx }
          : { width: 1, ...props.sx }
      }
    >
      {props.children}
    </Box>
  )
}

export default Column
