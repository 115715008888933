import { colors } from '../../colors'

import { faPhone } from '@fortawesome/pro-duotone-svg-icons'
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Section, Banner, ContactForm } from '../../components'

const ContactUsSection = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()

  const list = [
    <>
      <span>{t('home.contact_us.li1_highlight')}</span>{' '}
      {t('home.contact_us.li1_one')}
    </>,
    <>
      {t('home.contact_us.li2_one')}{' '}
      <span>{t('home.contact_us.li2_highlight')}</span>{' '}
      {t('home.contact_us.li2_two')}
      <span>{t('home.contact_us.li2_highlight_two')}</span>.
    </>,
    <>
      <span>{t('home.contact_us.li3_highlight')}</span>{' '}
      {t('home.contact_us.li3_one')}
    </>,
    <>
      {t('home.contact_us.li4_one')}{' '}
      <span>{t('home.contact_us.li4_highlight')}</span>{' '}
      {t('home.contact_us.li4_two')}
      <span>{t('home.contact_us.li4_highlight_two')}</span>
      {t('home.contact_us.li4_three')}
    </>,
  ]

  return (
    <Section icon={faPhone} title="section.contact_us">
      <Banner
        sx={{
          display: 'flex',
          rowGap: 5,
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
          alignItems: 'center',
          background: colors.gray[3],
          py: 3,
          px: [1.5, 2, 6],
          form: {
            flex: 0.9,
          },
        }}
      >
        <Stack
          sx={{
            flex: 1,
            ul: { m: 0, pl: ['20px', null, '40px'] },
            li: { my: 0.75, fontSize: [12, 14, 16], color: colors.gray[9] },
            span: { color: theme.palette.primary.main },
            gap: 1.75,
          }}
        >
          <Typography
            component="h2"
            variant={md ? 'title2' : 'headline'}
            sx={{ color: colors.gray[12], lineHeight: 1 }}
          >
            {t('home.contact_us.title')}
          </Typography>
          <ul>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Stack>
        <ContactForm />
      </Banner>
    </Section>
  )
}

export default ContactUsSection
