import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { articleListApi } from '../../apis/strapiApi'
import { PageContent } from '../../components'
import { ArticleCard } from '../../components/ArticleCard/ArticleCard'
import NewsFeaturedSlider from '../../components/NewsFeaturedSlider/NewsFeaturedSlider'
import { Article } from '../../types'
import { skeletonArticleList } from './newsList'

const News = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const { locale } = useParams()

  const [articleList, setArticleList] = useState<Article[]>()

  //TODO: Use SWR to fetch articles
  useEffect(() => {
    const getArticles = async () => {
      const data = await articleListApi(locale)
      setArticleList(data?.data)
    }
    getArticles()
  }, [locale])

  if (!articleList)
    return (
      <PageContent title="nav.news">
        <Helmet>
          <title>News - iMoon B2B</title>
        </Helmet>
        <ArticleCard
          id=""
          attributes={{}}
          size={'medium'}
          orientation={md ? 'horizontal' : 'vertical'}
          sx={{
            '.news__card--img': {
              width: [1, 720, 478.33, 682.9],
              height: ['calc(60vw - 48px)', 395, 262.41, 374],
            },
          }}
        />
        <Grid container spacing={2}>
          {skeletonArticleList.map((item) => (
            <Grid item xs={6} md={4} key={item.id}>
              <ArticleCard {...item} size={!sm ? 'small' : 'medium'} />
            </Grid>
          ))}
        </Grid>
      </PageContent>
    )

  return (
    <PageContent title="nav.news">
      <Helmet>
        <title>News - iMoon B2B</title>
      </Helmet>

      <NewsFeaturedSlider articleList={articleList.slice(0, 3)} />

      {/* Fetched from strapi */}
      <Grid container spacing={2}>
        {articleList.map((item) => (
          <Grid item xs={6} md={4} key={item.id}>
            <ArticleCard {...item} size={!sm ? 'small' : 'medium'} />
          </Grid>
        ))}
      </Grid>
    </PageContent>
  )
}

export default News
