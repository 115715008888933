import { Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { colors } from '../../../colors'
import { JDBAnimalNumbers } from '../../../types'

export const AnimalNumbers = (props: JDBAnimalNumbers) => {
  useEffect(() => {
    const textDom = document.getElementById(`an__text`)
    if (textDom) textDom.innerHTML = props.text
  }, [props])

  return (
    <Stack
      sx={{
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 3,
        img: { borderRadius: 3, width: [1, 'auto', '30%'] },

        '.MuiBox-root': {
          width: 1,
          position: 'relative',
          img: { position: 'relative', width: 1, zIndex: 1 },
          height: ['calc(134vw - 108px)', 300, 359, 494],
        },
      }}
    >
      <Typography
        sx={{
          color: colors.gray[9],
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: ['flex-start', 'center'],
          lineHeight: '28px',
        }}
        id="an__text"
        variant="bodySmallBold"
      ></Typography>
    </Stack>
  )
}
