import { Box, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Column } from '../../../components'
import { CrashColumn } from '../../../types'
import { useParams } from 'react-router-dom'

export const BetPanel = (props: CrashColumn) => {
  const { product } = useParams()
  useEffect(() => {
    props.details.map((item) => {
      const textDom = document.getElementById(`aboutgame--${item.id}__text`)
      if (textDom) textDom.innerHTML = item.text
    })
  }, [props])

  return (
    <Box>
      {props.details.map((item) => (
        <Stack
          sx={{
            flexDirection: ['column', null, 'row-reverse'],
            alignItems: 'center',
          }}
          key={item.id}
        >
          {item.img?.data?.attributes.url ? (
            <>
              <Column wide={!product?.includes('crashroyale')}>
                <Typography
                  paragraph
                  variant="body"
                  id={`aboutgame--${item.id}__text`}
                ></Typography>
              </Column>
              <Column wide={product?.includes('crashroyale')}>
                <Box
                  sx={{
                    position: 'relative',
                    img: {
                      position: 'relative',
                      width: 1,
                      zIndex: 1,
                    },
                    mb: 1,
                    ...(!product?.includes('crashroyale') && {
                      height: [1, 482, 199, 269],
                    }),
                  }}
                >
                  <img src={item.img.data.attributes.url} alt="crash points" />
                </Box>
              </Column>
            </>
          ) : (
            <Typography
              paragraph
              variant="body"
              id={`aboutgame--${item.id}__text`}
            ></Typography>
          )}
        </Stack>
      ))}
    </Box>
  )
}
