import {
  faBuilding,
  faEnvelope,
  faPhone,
  faUser,
} from '@fortawesome/pro-duotone-svg-icons'
import { LoadingButton } from '@mui/lab'
import { Box, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { FormInput } from '..'
import { contactFormApi } from '../../apis/contactFormApi'
import { useTranslation } from 'react-i18next'

export interface FormData {
  name: string
  company: string
  email: string
  phone: string
}

export const ContactForm = () => {
  const { enqueueSnackbar } = useSnackbar()
  // const { executeRecaptcha } = useGoogleReCaptcha()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [formData, setFormData] = useState<FormData>({
    name: '',
    company: '',
    email: '',
    phone: '',
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevValues) => ({ ...prevValues, [name]: value }))
  }

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    // if (!executeRecaptcha) {
    //   console.log('Execute recaptcha not yet available')
    //   return
    // }

    // const token = await executeRecaptcha()
    // Do whatever you want with the token
    const success = contactFormApi(formData)

    return success
  }, [formData])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const success = await handleReCaptchaVerify()
    if (success) {
      enqueueSnackbar('Submitted!', { variant: 'success' })
      setIsSubmitted(true)
      setTimeout(() => setIsSubmitted(false), 5000)
    } else enqueueSnackbar('Submit failed', { variant: 'error' })
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        sx={{
          '.MuiInputBase-root': { py: 0, height: 55 },
          '& > *:not(:last-child)': {
            mb: 1.5,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <FormInput
            name="name"
            placeholder={t('contact_us.your_name') || 'Your Name'}
            value={formData.name}
            onChange={handleChange}
            icon={faUser}
            required
            sx={{ marginInlineEnd: 1.5 }}
          />
          <FormInput
            name="company"
            placeholder={t('contact_us.company') || 'Company'}
            value={formData.company}
            onChange={handleChange}
            icon={faBuilding}
            required
          />
        </Box>
        <FormInput
          name="email"
          placeholder={t('contact_us.email') || 'Email'}
          value={formData.email}
          onChange={handleChange}
          type="email"
          icon={faEnvelope}
          required
        />
        <FormInput
          name="phone"
          placeholder={t('contact_us.phone') || 'Phone'}
          value={formData.phone}
          onChange={handleChange}
          icon={faPhone}
          required
        />
        <LoadingButton
          loading={loading}
          loadingIndicator={'Sending...'}
          type="submit"
          color={isSubmitted ? 'success' : 'primary'}
          variant="contained"
        >
          {isSubmitted ? 'Submitted!' : t('general.submit')}
        </LoadingButton>
      </Stack>
    </form>
  )
}
