import { Card, CardProps, Skeleton } from '@mui/material'

const GameCardSkeleton = (props: CardProps) => {
  return (
    <Card
      elevation={4}
      sx={{
        display: 'flex',
        borderRadius: ['6px', '8px', '12px'],
        overflow: 'hidden',
        background: 'none',
        height: ['calc(55vw - 15.5px)', 205, 176, 240],
        '.MuiSkeleton-root': {
          height: 1,
          width: 1,
          borderRadius: ['6px', '8px', '12px'],
        },
        ...props.sx,
      }}
    >
      <Skeleton variant="rectangular" />
    </Card>
  )
}

export default GameCardSkeleton
