import { LoadingButtonProps } from '@mui/lab'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface PlayDemoButtonProps {
  gameId: string
  slug: string
}

// TODO: Refactor code, clean up toast and verification key states
const PlayDemoButton = (props: PlayDemoButtonProps & LoadingButtonProps) => {
  const { t, i18n } = useTranslation()

  return (
    <Button
      variant={props.variant}
      color={props.color}
      size="small"
      sx={props.sx}
      href={`${i18n.resolvedLanguage}/play/${props.slug}`}
      target="_blank"
      fullWidth={props.fullWidth}
      className="demo-button"
    >
      {t('general.play_demo')}
    </Button>
  )
}

export default PlayDemoButton
