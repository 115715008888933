export const colors = {
  primary: {
    1: '#d04e03',
    2: '#df5900',
    3: '#ee6700',
    4: '#fb8111',
    5: '#f78e2c',
    6: '#f39b49',
    7: '#f9aa62',
    8: '#fdbd75',
    9: '#f9d2a4',
    10: '#fce1c2',
    11: '#feecd8',
    12: '#fff5ea',
  },
  secondary: {
    1: '#002f61',
    2: '#004084',
    3: '#0053ab',
    4: '#005fc3',
    5: '#0c70db',
    6: '#1f84ef',
    7: '#4ca1fb',
    8: '#72b6ff',
    9: '#90c6ff',
    10: '#b9dbff',
    11: '#d6eaff',
    12: '#eaf4ff',
  },
  tertiary: {
    1: '#005415',
    2: '#00661a',
    3: '#047020',
    4: '#07932c',
    5: '#0aad36',
    6: '#0fc943',
    7: '#22e54b',
    8: '#55f570',
    9: '#95ffa6',
    10: '#bfffca',
    11: '#e3ffe8',
    12: '#f1fff4',
  },
  gray: {
    1: '#04020B',
    2: '#110C20',
    3: '#1F1933',
    4: '#332C4A',
    5: '#463E5E',
    6: '#5C5474',
    7: '#746D89',
    8: '#8B859F',
    9: '#A39EB4',
    10: '#BBB7C9',
    11: '#D5D2DF',
    12: '#EDEBF4',
  },
}

export const gradients = {
  1: 'linear-gradient(180deg, rgba(34, 30, 51, 0) 9.98%, rgba(34, 30, 51, 0.98) 35.3%, #221E33 61.67%, rgba(34, 30, 51, 0) 100%)',
  2: 'linear-gradient(91.34deg, #968EB0 22.38%, rgba(150, 142, 176, 0.38) 115.82%)',
  3: 'linear-gradient(285.19deg, #2A243A 7.78%, rgba(42, 36, 58, 0) 88.57%)',
  4: 'linear-gradient(180deg, #3C3553 0%, rgba(60, 53, 83, 0) 100%);',
  5: 'linear-gradient(180deg, #1D1929 0%, #2D2740 100%)',
  6: 'linear-gradient(180deg, rgba(42, 36, 58, 0) 0%, #2A243A 21.88%, #2A243A 68.23%, rgba(42, 36, 58, 0) 100%)',
  7: 'linear-gradient(180deg, rgba(42, 36, 58, 0) 0%, #1F1933 100%)',
}

export const shadows = {
  0: '0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)',
}
export const tierColors = {
  // no tier
  0: {
    progressbarGradient: 'linear-gradient(90deg, #06f 21.41%, #09f 92.41%)',
    benefitBgGradient:
      'linear-gradient(180deg, rgba(30, 133, 241, 0.1) 0%, rgba(0, 0, 0, 0) 55.37%), #1D1929;',
    bg: colors.gray[1],
    main: colors.gray[3],
    dark: colors.gray[5],
    light: colors.gray[3],
    border: colors.gray[9],
  },
  // blue
  1: {
    progressbarGradient: 'linear-gradient(90deg, #06f 21.41%, #09f 92.41%)',
    benefitBgGradient:
      'linear-gradient(180deg, rgba(30, 133, 241, 0.2) 0%, rgba(0, 0, 0, 0) 55.37%), #1D1929;',
    bg: colors.secondary[7],
    main: '#06fe',
    dark: colors.secondary[2],
    light: colors.secondary[10],
    border: colors.secondary[6],
  },
  // bronze
  2: {
    progressbarGradient:
      'linear-gradient(90deg, #AD5846 21.41%, #F6785D 92.41%)',
    benefitBgGradient:
      'linear-gradient(180deg, rgba(173, 88, 70, 0.2) 0%, rgba(0, 0, 0, 0) 55.37%), #1D1929;',
    bg: '#F6785Dee',
    main: '#F6785Dee',
    dark: '#7D2C1A',
    light: colors.primary[12],
    border: '#C04C33',
  },
  // silver
  3: {
    progressbarGradient: 'linear-gradient(90deg, #999 21.41%, #ddd 92.41%);',
    benefitBgGradient:
      'linear-gradient(180deg, rgba(244, 248, 251, 0.2) 7.79%, rgba(0, 0, 0, 0) 55.37%), #1D1929;',
    bg: '#E4EBF2',
    main: '#999e',
    dark: colors.gray[5],
    light: colors.gray[8],
    border: '#C1CEF1',
  },
  // gold
  4: {
    progressbarGradient:
      'linear-gradient(90deg, #ffb423 21.41%, #ffd88d 92.41%)',
    benefitBgGradient:
      'linear-gradient(180deg, rgba(255, 214, 135, 0.2) 0%, rgba(0, 0, 0, 0) 55.37%), #1D1929;',
    bg: '#FFD88D',
    main: '#ffb423ee',
    dark: colors.gray[2],
    light: '#E29704',
    border: '#FFD88D',
  },
  // platinum
  5: {
    progressbarGradient:
      'linear-gradient(90deg, #6F7CC1 3.14%, #8F9Cf1 98.74%)',
    benefitBgGradient:
      'linear-gradient(180deg, rgba(170, 184, 253, 0.2) 1.44%, rgba(149, 157, 227, 0) 55.37%), #1D1929;',
    bg: '#ABB8FF',
    main: '#6F7CC1ee',
    dark: colors.gray[3],
    light: colors.gray[6],
    border: '#6F7CC1',
  },
}
