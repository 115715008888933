import {
  IconDefinition,
  faCalendarDays,
  faChevronRight,
  faClock,
  faLanguage,
  faSackDollar,
  faUserSecret,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import { FC, FormEvent, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useTranslation } from 'react-i18next'
import { colors } from '../../colors'
import { getUploadUrlApi, uploadApi } from '../../apis/uploadApi'
import { PageContent } from '../../components'

export interface CareerDetailsProps {
  jobTitle: string
  responsibilities: string[]
  requirements: string[]
  skills: string[]
  preferredSkills: string[]
  languages: string[]
  workingTime: string
  jobType: string
  gender: string
  salary: string
  location: string
}

const CareerDetails = (props: CareerDetailsProps) => {
  return (
    <PageContent
      title={props.jobTitle}
      sx={{
        '.MuiListItemIcon-root': { minWidth: 36 },
        '.MuiListItemText-primary': {
          color: colors.gray[9],
        },
      }}
    >
      {props.responsibilities.length > 0 && (
        <RegularList title="Responsibilities" list={props.responsibilities} />
      )}
      <RegularList title="Requirements" list={props.requirements} />
      <DetailsList {...props} />
      <SkillList title="Required Skills" list={props.skills} />
      <SkillList title="Preferred Skills" list={props.preferredSkills} />
      <UploadCV />
    </PageContent>
  )
}

const RegularList: FC<{ title: string; list: string[] }> = (props) => {
  return (
    <Stack>
      <Typography variant="headline">{props.title}</Typography>
      <List>
        {props.list.map((item, index) => (
          <ListItem disablePadding key={index}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faChevronRight} fontSize={16} />
            </ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

const DetailsList = (props: CareerDetailsProps) => {
  const list: {
    icon: IconDefinition
    label: string
    text: string[] | string
  }[] = [
    {
      icon: faLanguage,
      label: 'Languages',
      text: props.languages,
    },
    {
      icon: faClock,
      label: 'Work Timings',
      text: props.workingTime,
    },
    {
      icon: faCalendarDays,
      label: 'Job Type',
      text: props.jobType,
    },
    {
      icon: faUserSecret,
      label: 'Gender',
      text: props.gender,
    },
    {
      icon: faSackDollar,
      label: 'Salary',
      text: `Negotiable`,
    },
    // {
    //   icon: faLocationDot,
    //   label: 'Location',
    //   text: props.location,
    // },
  ]
  return (
    <Stack>
      <Typography variant="subHeadline">Additional Details</Typography>
      <List>
        {list.map(({ icon, text, label }, index) => (
          <ListItem disablePadding key={index}>
            <ListItemIcon>
              <FontAwesomeIcon icon={icon} fontSize={16} />
            </ListItemIcon>
            <ListItemText primary={`${label}: ${text}`} />
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

const SkillList: FC<{ title: string; list: string[] }> = (props) => {
  return (
    <Stack sx={{ gap: 1 }}>
      <Typography variant="subHeadline">{props.title}</Typography>
      <Stack
        sx={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
          '.MuiChip-root': { minWidth: 75 },
        }}
      >
        {props.list.map((item) => (
          <Chip label={item} variant="filled" key={item} />
        ))}
      </Stack>
    </Stack>
  )
}

const UploadCV = () => {
  const fileTypes = ['PDF', 'JPEG', 'JPG', 'PNG']
  const mimeTypes = ['application/pdf', 'image/jpeg', 'image/png']
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<File>()
  const handleChange = (file: File) => {
    setFile(file)
  }
  const { t } = useTranslation()
  const [hideChildren, setHideChildren] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const children = () =>
    !hideChildren ? (
      <Stack sx={{ gap: 0.75 }}>
        {file ? (
          <Typography variant="subHeadline">{file.name}</Typography>
        ) : (
          <>
            <Typography variant="subHeadline">{t('careers.p1')}</Typography>
            <Typography variant="bodyBold" sx={{ color: colors.gray[7] }}>
              {fileTypes.join(', ')} (5MB Max)
            </Typography>
          </>
        )}
      </Stack>
    ) : (
      <></>
    )
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    console.log('file::', file)
    if (file) {
      const uploadCV = async () => {
        const uploadUrl =
          file &&
          (await getUploadUrlApi('cv', file?.type.split('/')[1], file?.type))
        if (uploadUrl) {
          const upload = await uploadApi(uploadUrl, file, file?.type)

          upload
            ? enqueueSnackbar('CV sent successfully!', { variant: 'success' })
            : enqueueSnackbar('Failed to send...', { variant: 'error' })
          setLoading(false)
        }
      }

      uploadCV()
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack sx={{ textAlign: 'center', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            label: {
              flex: 1,
              border: `2px dashed ${colors.gray[5]}`,
              borderRadius: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 240,
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.6,
              },
            },
          }}
        >
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            children={children()}
            onTypeError={(file: File) =>
              enqueueSnackbar('File type not supported', { variant: 'error' })
            }
            onSizeError={(file: File) =>
              enqueueSnackbar('Maximum file size is 5mb', { variant: 'error' })
            }
            maxSize={5}
            onDraggingStateChange={(dragging: boolean) =>
              setHideChildren(dragging)
            }
          />
        </Box>
        <Box>
          <LoadingButton
            loadingIndicator="Sending..."
            variant="contained"
            type="submit"
            loading={loading}
            disabled={!file}
          >
            {t('careers.apply_now')}
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  )
}

export default CareerDetails
